import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Application, Environments } from '../../../shared/types/type';
import ModalButton from '../../common/ModalButton/ModalButton';
import { saveApplication } from '../config.actions';
import { selectApplicationsDetails } from '../config.selector';
import EnvironmentSetupItem from './EnvironmentSetupItem';
var _ = require('lodash');
interface MatchParams {
    tenantName?: string,
    appName?: string,
    tenantId?: string,
    appId?: string
}
interface Props extends RouteComponentProps<MatchParams> {

}
interface Props {

}

const EnvironementSetup = (props: Props) => {

    const applicationInfo = useSelector(selectApplicationsDetails);
    let initialstate: Application = _.cloneDeep(applicationInfo.applications[0]);
    const [applications, setApplication] = useState(initialstate);
    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const initialEnvsList: Environments | undefined = applicationInfo?.applications[0]?.environments;
    const [envsList, setEnvsList] = useState(initialEnvsList);
    const dispatch = useDispatch();

    const onSubmitAppDetails = () => {

        const application = _.cloneDeep(applications);
        application.tenantId = props?.match?.params?.tenantId;
        application.environments = envsList;
        dispatch(saveApplication(application))
        setShowAlertMessage(true)
    }
    useEffect(() => {
        setEnvsList(initialEnvsList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationInfo])

    const environmentOnchange = (key: string, value: string, index: string) => {

        if (key === 'defaultEnvForEditor') {
            setApplication({
                ...applications,
                [key]: parseInt(value)
            })
        }
        let envListData = { ...envsList };

        envListData[index] = {
            ...envListData[index],
            [key]: value
        }
        setEnvsList(envListData);
    }
    const closePopup = () => {
        setShowAlertMessage(false)
    }
    return (
        <div className='config-body'>
            <p className='config-heading'>Environments setup</p>
            <form >
                {
                    envsList && Object.keys(envsList).map((envIndex: string, i) => {
                        return (
                            <EnvironmentSetupItem
                                key={i}
                                defaultEnvForEditor={applications.defaultEnvForEditor}
                                index={i + 1}
                                name={envsList[envIndex].name}
                                url={envsList[envIndex].url}
                                environmentOnchange={environmentOnchange}
                                enablePlayer={envsList[envIndex].enablePlayer}

                            />
                        )
                    })
                }
                <ModalButton
                    classes={'config-submit-button'}
                    loading={applicationInfo.submitLoading}
                    theme='white'
                    headerText='Update Application'
                    MainText={<span>You are trying to Update Application <strong>{applications?.name}</strong> </span>}
                    buttonText='Submit'
                    onSubmit={onSubmitAppDetails}
                />

                {showAlertMessage && applicationInfo.message && !applicationInfo.submitLoading && <div className='error-message'>
                    {applicationInfo.message}
                    <span className='closebtn' onClick={closePopup}>&times;</span>

                </div>}
            </form>
        </div>
    );
}
export default EnvironementSetup;