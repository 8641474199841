import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Environments, featuresFlag } from '../../../shared/types/type';
import DropDown from '../../common/dropdown/dropdown';
import EnvironmentList from '../../common/Environment/EnvironMentList';
import CustomLoader from '../../common/loader/component.loader';
import ModalButton from '../../common/ModalButton/ModalButton';
import { isEnvironmentsValid, isValidObject } from '../../../utils/utils';
import { fetchingTenantInfo } from '../TenantInfo/tenantInfo.actions';
import { getTenantInfo } from '../TenantInfo/tenantInfo.selector';
import { addApplication, fetchLocaleData } from './addApplication.actions';
import './addApplication.css';
import { selectLocaleData } from './addApplication.selector';
interface initialStateTypes {
    name: any,
    appTypeCode: string,
    tenantId: number,
    environments: any,
    playerDefaultLocaleId: number,
    isActive: boolean,
    localeList: Array<object>,
    defaultLocaleId: number
}
interface Props {
    tenantID: number,
    tenantName: string,
    tenantKey: string,
    onCancel: (submit: boolean) => void;
}


const AddAplication = (props: Props) => {

    const dispatch = useDispatch();
    const tenantInfo = useSelector(getTenantInfo);
    const featuresFlagData: featuresFlag = tenantInfo.tenantInfoDetail;

    const getLocaleData: any = useSelector(selectLocaleData)
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);

    const initialstate: initialStateTypes = {
        name: '',
        appTypeCode: 'sfLight',
        tenantId: props.tenantID,
        environments: {},
        playerDefaultLocaleId: 1,
        isActive: true,
        defaultLocaleId: 1,
        localeList: getLocaleData && getLocaleData.localeList,
    }

    const [localeDetails, setLocaleDetails] = useState(initialstate);
    const [showPopup, setShowPopup] = useState(false);

    /************** fetches app types and locales ******************/

    useEffect(() => {
        dispatch(fetchLocaleData());
        dispatch(fetchingTenantInfo(props.tenantKey));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(isValidObject(localeDetails) && isApiCallValid(localeDetails.name, localeDetails.environments)){
            setSubmitDisabled(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localeDetails])


    /************* event handlers ***************/

    const handleSubmit = async () => {
        let inputData = { ...localeDetails };
        inputData.localeList = (getLocaleData && getLocaleData.localeList);
        try {
            dispatch(addApplication(inputData));
            setShowPopup(true);
        } catch (e){
            // tslint:disable-next-line: no-console
            console.log(e);
        }
    }

    const handleChange = (key: keyof initialStateTypes, value: string) => {
        let localedata = { ...localeDetails };
        if (key === 'defaultLocaleId' || key === 'playerDefaultLocaleId') {
            localedata = {
                ...localeDetails,
                [key]: parseInt(value)
            }
        }
        else {
            localedata = {
                ...localeDetails,
                [key]: value
            }
        }
        setLocaleDetails(localedata);
    }
    const handleEnviromentChange = (event: ChangeEvent<HTMLInputElement>, id: number) => {

        const { name, value } = event.target;
        let localedetails = { ...localeDetails };
        let environmentList = localedetails.environments;

        if (!environmentList[id]) {
            environmentList[id] = {}
            environmentList[id]['order'] = id;
            environmentList[id][name] = value;
        }
        else {
            environmentList[id]['order'] = id;
            environmentList[id][name] = value;
        }
        setLocaleDetails({
            ...localeDetails,
            environments: environmentList
        })

    }
    const deleteEnvironmentEntry = (id: number) => {

        let localedetails = { ...localeDetails };
        let environmentList = localedetails.environments;
        delete environmentList[id];
        setLocaleDetails({
            ...localeDetails,
            environments: environmentList
        })
    }
    const closePopup = () => {
        setShowPopup(false)
    }

    const isApiCallValid = (name: string, environments: Environments) => {

        let isEnvValid = isEnvironmentsValid(environments, featuresFlagData['urlRegex'].status);
        return isEnvValid;
    }

    if (getLocaleData.loading) {
        return <CustomLoader />
    }
    return (
        <div >
            <p className='tenant-name-heading'>
                {props.tenantName}
            </p>
            <form
                onSubmit={handleSubmit}
            >
                {/******************* GENERAL SETTINGS  *********************/}

                <p className='section-title'>General Settings</p>

                <div className='input-container-add'>
                    <label htmlFor='name'>App name</label>
                    <input
                        type='text'
                        id='name'
                        name='name'
                        value={localeDetails.name}
                        onChange={e => handleChange('name', e.target.value)}
                        placeholder='App name'
                        required
                    />
                </div>

                <div className='input-container-add '>
                    <label>App Type code</label>
                    <DropDown
                        name='appTypeCode'
                        apptype={true}
                        className='all-selector option-css'
                        value={localeDetails.appTypeCode}
                        payload={getLocaleData.payloadList.payload}
                        onChange={handleChange}
                    />
                </div>

                <p className='section-title'>Environments setup</p>
                <EnvironmentList
                    classes={'input-container-add'}
                    handleEnviromentChange={handleEnviromentChange}
                    deleteEnvironmentEntry={deleteEnvironmentEntry}
                    publishingEnvironment={parseInt(featuresFlagData?.publishingEnvironments?.status)}
                />

                <p className='section-title'>Locale Settings</p>
                <div>
                    <div className='input-container-add'>
                        <label htmlFor='defaultLocaleId'> Default Locale ID </label>
                        <DropDown
                            name='defaultLocaleId'
                            value={localeDetails.defaultLocaleId}
                            payload={getLocaleData.localeList}
                            onChange={handleChange}
                            className='all-selector option-css'

                        />
                    </div>
                    <div className='input-container-add'>
                        <label htmlFor='playerDefaultLocaleId'> Player Default Locale Id</label>
                        <DropDown
                            name={'playerDefaultLocaleId'}
                            value={localeDetails.playerDefaultLocaleId}
                            payload={getLocaleData.localeList}
                            onChange={handleChange}
                            className='all-selector option-css'
                        />
                    </div>
                </div>
                <div className='Button-Conatiner-AddAppilication ' >
                    <ModalButton
                        classes={'config-submit-button'}
                        loading={getLocaleData.submitLoading}
                        theme='white'
                        headerText='Add Application'
                        MainText={<span>You are trying to add Application <strong>
                            {localeDetails.name}
                            </strong> into the tenant Id:<strong> {props.tenantID}</strong> and Tenant Name: <strong>
                                {props.tenantName}</strong> </span>}
                        buttonText='Submit'
                        onSubmit={handleSubmit}
                        disabled={submitDisabled}
                    />
                    {showPopup && getLocaleData.alertMessage && !getLocaleData.submitLoading && <div className='error-message'>
                        {getLocaleData.alertMessage}
                        <span className='closebtn'
                            onClick={getLocaleData.submit ? () => props.onCancel(true) : closePopup}>&times;</span>

                    </div>}
                </div>

            </form>
        </div>
    )
}

export default AddAplication;