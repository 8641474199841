export const activitesDemoData = {
    'from': '2021-03-01',
    'to': '2021-04-30',
    'tenantId': 232,
    'tenantKey': 'bHMKgeSV',
    'appId': 504,
    'email': 'madhuriqa@yopmail.com',
    'password': 'Apty@123',
    'domain': 'http://14.99.46.185:8889/',
    'envMode': 'qa',
    'applicationEnv': 1,
    'activities': [
        {
            'departmentName': 'nasales',
            'activitiesInfo': [
                {
                    'activityId': 156,
                    'session_duration': 300,
                    'completion_rate': 30,
                    'frequency': 5
                },
                {
                    'activityId': 155,
                    'session_duration': 200,
                    'completion_rate': 60,
                    'frequency': 5
                },
                {
                    'activityId': 157,
                    'session_duration': 250,
                    'completion_rate': 50,
                    'frequency': 3
                },
                {
                    'activityId': 158,
                    'session_duration': 260,
                    'completion_rate': 70,
                    'frequency':5
                }
            ]
        },
        {
            'departmentName': 'emeasales',
            'usersCount': 100,
            'activitiesInfo': [
                {
                    'activityId': 155,
                    'session_duration': 250,
                    'completion_rate': 10,
                    'frequency': 5
                },
                {
                    'activityId': 156,
                    'session_duration': 190,
                    'completion_rate': 55,
                    'frequency': 5
                },
                {
                    'activityId': 157,
                    'session_duration': 140,
                    'completion_rate': 50,
                    'frequency': 5
                },
                {
                    'activityId': 158,
                    'session_duration': 240,
                    'completion_rate': 50,
                    'frequency': 4
                }
            ]
        },
        {
            'departmentName': 'Finance',
            'usersCount': 50,
            'activitiesInfo': [
                {
                    'activityId': 155,
                    'session_duration': 250,
                    'completion_rate': 55,
                    'frequency': 5
                },
                {
                    'activityId': 156,
                    'session_duration': 260,
                    'completion_rate': 65,
                    'frequency': 5
                },
                {
                    'activityId': 157,
                    'session_duration': 250,
                    'completion_rate': 80,
                    'frequency': 1
                }
            ]
        },
        {
            'departmentName': 'IT',
            'usersCount': 80,
            'activitiesInfo': [
            	{
                    'activityId': 155,
                    'session_duration': 25,
                    'completion_rate': 20,
                    'frequency': 4
                },
                {
                    'activityId': 158,
                    'session_duration': 250,
                    'completion_rate': 55,
                    'frequency': 6
                },
                {
                    'activityId': 157,
                    'session_duration': 260,
                    'completion_rate': 40,
                    'frequency': 5
                },
                {
                    'activityId': 156,
                    'session_duration': 250,
                    'completion_rate': 50,
                    'frequency': 4
                }
            ]
        }
    ]
}