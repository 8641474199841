import { featuresFlag } from '../../../shared/types/type';
import { createReducer } from '@reduxjs/toolkit';
import { fetchedTenantInfoFailed, fetchedTenantInfoSuccess, fetchingTenantInfo, setAppData } from './tenantInfo.actions';
export interface TenantInfo {
     loading:boolean;
     tenantInfoDetail:featuresFlag;
     appName: string;
     tenantName: string;
     tenantId: number;
     appId: number;
     tenantKey: string
  }

const initialState: TenantInfo = {
  loading:false,
  tenantInfoDetail:{} as featuresFlag,
  appName: '',
  tenantName: '',
  tenantId: 0,
  appId: 0,
  tenantKey: ''
}

export const tenantInfoReducer = createReducer(initialState,builder => {
  builder.addCase(fetchingTenantInfo, (state, action) => {
    return {
      ...state,
      loading:true,
    };
  });

  builder.addCase(fetchedTenantInfoSuccess, (state, action) => {
    return {
      ...state,
      loading:false,
      tenantInfoDetail:action.payload
    };
  });
  builder.addCase(fetchedTenantInfoFailed, (state, _) => {
    return {
      ...state,
      loading:false
    };
  });
  builder.addCase(setAppData, (state, action) => {
    const data = action.payload;
    return {
      ...state,
      ...data
    };
  });

});
