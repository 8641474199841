import React from 'react';
import { ChangeEvent } from 'react';

interface Props {
    id: number,
    classes?: string
    mandatoryEnv: boolean,
    lastEnv: boolean
    handleEnviromentChange?: (event: ChangeEvent<HTMLInputElement>, id: number) => void;
    deleteEnvironment?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => void;
}

function Environment(props: Props) {
    return (
        <div className='environment-container' >
            { props.id !== 1 &&
                <div className='line-env'>

                </div>
            }

            <p className='header-env'>Environment #{props.id}</p>
            <div  >
                <label>Environment URL</label>
                <input
                    className={props.classes ? props.classes : 'input-container'}

                    type='text'
                    name='url'
                    onChange={event => props.handleEnviromentChange && props.handleEnviromentChange(event, props.id)}
                    placeholder='https://environment.com'
                    required
                />
            </div>

            <div id={'del-env' + props.id}
            >
                <label>Environment Name</label>
                <input
                    className={props.classes ? props.classes : 'input-container'}
                    type='text'
                    name='name'
                    onChange={event => props.handleEnviromentChange && props.handleEnviromentChange(event, props.id)}
                    placeholder='Name'
                    required
                />

                {!props.mandatoryEnv &&
                    <button
                        type='button'
                        className='del-env'
                        onClick={event => props.deleteEnvironment && props.deleteEnvironment(event, props.id)}
                    >
                        Delete
                    </button>
                }
            </div>

        </div>
    )
}

export default Environment;