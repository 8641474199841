import { InjectedHookType } from '../../shared/types/type';

import { createAction } from '@reduxjs/toolkit';
export const fetchInjectedHook=createAction<number>('FETCH_INJECTEDHOOK_INIT');
export const fetchInjectedHookSuccess=createAction<string>('FETCH_INJECTEDHOOK_SUCCESS');
export const fetchInjectedHookFailed=createAction('FETCH_INJECTEDHOOK_FAILED');
export const saveInjectedHook=createAction<InjectedHookType>('SAVE_INJECTEDHOOK_INIT');
export const saveInjectedHookFailed=createAction('SAVE_INJECTEDHOOK_FAILED');
export const saveInjectedHookSuccess=createAction('SAVE_INJECTEDHOOK_SUCCESS');



