
import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import axiosInstance from '../../../apis/axiosInstance';
import { fetchedTenantSuccess, fetchingTenant, fetchedTenantFailed } from './tenant.actions';
export interface requestOptions {
    method: string;
    headers: HeadersInit;
    redirect: RequestRedirect;
}
function* TenantList(action: PayloadAction<{}>) {
    try {
        const appResponse = yield axiosInstance.get('/accounts/applications/v3/tenantApps');
        const appResult = yield appResponse.data;
        if (appResponse.status === 200) {
            yield put(fetchedTenantSuccess(appResult))
        }
    }
    catch (error) {
        yield put(fetchedTenantFailed())
    }
}
export const tenantEffects = [
    takeEvery([fetchingTenant.type], TenantList),
];
