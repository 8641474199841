import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router';
import { fetchUserDetails } from './UserList.actions';
import { Card, CardBody, Col, Row, Container } from 'shards-react';
import { UserDetailsTypes } from '../../shared/types/type';
import { getUserDetails } from './UserList.selector'
import CustomLoader from '../common/loader/component.loader';
import CustomModal from '../common/CustomPopUp/CustomPopup';
import AddUser from '../AddUser/component.adduser';
import { Link } from 'react-router-dom';

interface MatchParams {

    tenantId?: string,

}
interface Props extends RouteComponentProps<MatchParams> {

}
const UserList = (props: Props) => {
    const dispatch = useDispatch();
    let UserDetails = useSelector(getUserDetails);
    let userData: UserDetailsTypes[] = UserDetails.Users;
    const [showUserPopup, setShowUserPopup] = useState(false);

    useEffect(() => {
        let tenantID: number | undefined = parseInt(props.match.params.tenantId || '');
        dispatch(fetchUserDetails(tenantID as number))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Container fluid className='main-content-container px-4 container-fluid margin-top-100'>
            <CustomModal
                footer={null}
                title={'Add New Users'}
                visible={showUserPopup}
                closable={true}
                maskClosable={false}
                keyboard={false}
                hideHeader={false}
                modalClassName='add-application-container'
                onCancel={setShowUserPopup}
                closeCallback={setShowUserPopup}
            >
                <AddUser tenantID={parseInt(props.match.params.tenantId || '')} />
            </CustomModal>
            <Row>
                <Col>
                    <Link to='/' className='back-button'>
                        <i className='fa fa-arrow-left pulse'></i>
                    </Link></Col>
            </Row>
            <Row noGutters className='page-header py-4 '>
                <Col xs='12' sm='11 '>
                    <h3 className='page-title'>User Details</h3>
                </Col>
                <Col><span style={{ fontSize: '40px' }} onClick={() => { setShowUserPopup(true) }} ><i title='Tenant info' className='fa fa-user-plus'></i></span></Col>

            </Row>
            <Row>
                {/* Users Details */}
                <Col lg='12' md='12' sm='12' className='mb-2'>
                    <Row >
                        <Col>
                            <Card small className='mb-4'>
                                <CardBody className='p-0 pb-3'>
                                    <table className='table mb-0'>
                                        <thead className='bg-light'>
                                            <tr>
                                                <th>Id</th>
                                                <th scope='col' className='border-0'>
                                                    Name
                          </th>
                                                <th scope='col' className='border-0'>
                                                    Tenant Id
                          </th>
                                                <th scope='col' className='border-0'>
                                                    Email
                          </th>
                                                <th>
                                                    TenantKey
                          </th>
                                                <th>IsEmailVerified</th>
                                                <th>Role Name</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userData && userData.length > 0 ? (
                                                userData.map((user, idx) => {
                                                    return (
                                                        <tr >
                                                            <td>{user.id}</td>
                                                            <td>{user.fullName}</td>
                                                            <td>{user.tenantId}</td>
                                                            <td>
                                                                <a href={`mailto:${user.id}`}>{user.email}</a>
                                                            </td>
                                                            <td>{user.tenantKey}</td>
                                                            <td>{user.isEmailVerified ? 'True' : 'False'}</td>
                                                            <td>{user.roleName[0]}</td>
                                                        </tr>
                                                    );
                                                })
                                            )
                                                : UserDetails.loading ? (
                                                    <tr style={{ alignItems: 'center' }}>
                                                        <td colSpan={6}>
                                                            <CustomLoader />
                                                        </td>
                                                    </tr>
                                                ) : (
                                                        <tr style={{ alignItems: 'center' }}>
                                                            <td colSpan={9}>
                                                                <strong>Data Not Found</strong>{' '}
                                                            </td>
                                                        </tr>
                                                    )
                                            }
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}


export default UserList;