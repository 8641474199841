import React from 'react'


interface Props {
    urlList: Array<object>,
    deleteUrl: (key: number) => void
}

const UrlConfig = (props: Props) => {

    var urlList = props.urlList && props.urlList.length >= 1 && props.urlList.map((url: any, index: number) => {
        return (
            <li key={index}>
                <p>{url}</p>
                <i className='fa fa-trash' onClick={() => { props.deleteUrl(index) }}></i>
            </li>
        )
    })

    return (
        <ul className='url-config-list'>
            {urlList}
        </ul>
    )

}

export default UrlConfig;