import { fetchingApplication, fetchedApplicationSuccess,
  fetchedApplicationFailed, saveApplication, saveApplicationFailed,
  saveApplicationSucccess, customPublishInit, customPublishSuccess, customPublishFailed } from './config.actions';
import { Application } from '../../shared/types/type';

import { createReducer } from '@reduxjs/toolkit';


export interface ApplicationState {
  loadingApplication: boolean;
  applications: Application[];
  submitLoading: boolean,
  message: string,
  published: boolean,
  publishLoader:boolean,
  publishMessage:string

}

const initialState: ApplicationState = {
  loadingApplication: false,
  applications: [],
  submitLoading: false,
  message: '',
  published: false,
  publishLoader:false,
  publishMessage:''
}

export const configApplicationReducer = createReducer(initialState,builder => {
  builder.addCase(fetchingApplication, (state, action) => {
    return {
      ...state,
      loadingApplication: true,
      publishMessage:''
    };
  });

  builder.addCase(fetchedApplicationSuccess, (state, action: any) => {
    return {
      ...state,
      loadingApplication: false,
      applications: action.payload,
      publishMessage:''
    };
  });
  builder.addCase(fetchedApplicationFailed, (state, _) => {
    return {
      ...state,
      loadingApplication: false,
      publishMessage:''
    };
  });
  builder.addCase(saveApplication, (state, _) => {
    return {
      ...state,
      submitLoading: true,
      publishMessage:''
    };
  });
  builder.addCase(saveApplicationSucccess, (state, _) => {
    return {
      ...state,
      submitLoading: false,
      message: 'Saved Data SuccessFully',
      publishMessage:''
    }
  });
  builder.addCase(saveApplicationFailed, (state, _) => {
    return {
      ...state,
      submitLoading: false,
      message: 'Failed to save details',
      publishMessage:''
    }
  });

  builder.addCase(customPublishInit, (state, _) => {
    return {
      ...state,
      publishLoader: true,
      published: false,
    }
  })
  builder.addCase(customPublishSuccess, (state, _) => {
    return {
      ...state,
      publishLoader: false,
      published: true,
      publishMessage:'SuccessFully Published Status'
    }
  })
  builder.addCase(customPublishFailed, (state, _) => {
    return {
      ...state,
      publishLoader: false,
      published: false,
      publishMessage:'Failed to  Publish Status',
    }
  })

});

