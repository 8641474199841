import { fetchUserDetails, fecthUserDetailsFailed, fecthUserDetailsSuccess } from './UserList.actions';

import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import axiosInstance from '../../apis/axiosInstance';


function* UserList(action: PayloadAction<number>) {
    try {
        const appResponse = yield axiosInstance.get(`/accounts/user/v1/tenant/${action.payload}`);
        const appResult = yield appResponse.data;
        if (appResponse.status === 200) {
            yield put(fecthUserDetailsSuccess(appResult.Payload))
        }
    }
    catch (error) {
        yield put(fecthUserDetailsFailed())
    }
}
export const userDetailsEffects = [
    takeEvery([fetchUserDetails.type], UserList),
];
