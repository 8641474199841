import React  from 'react';
import { MigrationDataCheck } from '../../shared/types/type';
import AInput from '../common/Ainput/Ainput';
import CustomButton from '../common/button/button';
import axiosInstance from '../../apis/axiosInstance';
import DropDown from '../common/dropdown/dropdown';

const AddMigrationDetails: React.FC = () => {
    let dropDownOptions = [{
        name:'Development',
        code:'https://development.apty.io'
    },{
        name:'QA',
        code:'https://qa.apty.io'
    },{
        name:'Staging',
        code:'https://staging.apty.io'
    },{
        name:'Labs',
        code:'https://labs.apty.io'
    }]

    const migrationData = new Map<MigrationDataCheck, string | number>()

    function onChange(key: MigrationDataCheck, value: string | number) {
        migrationData[key] = value;
        return true;
    }

    async function onSubmit(){
        await axiosInstance.post('http://52.206.58.31:8084/migrate/all',migrationData);
    }
  return (
    <div className='form-container tenant-add-form'>
        <p className='login-heading'>{process.env.REACT_APP_TITLE}</p>
        <div className='login-input-container'>
        <label>Source Environment</label>
            <DropDown
                name='sourceConnectionString'
                value={dropDownOptions[0]}
                payload={dropDownOptions}
                onChange={onChange}
                apptype= {true}
            />
            <label>Source App ID</label>
            <AInput
                type='number'
                name='SourceAppId'
                placeholder='Enter your Source App ID'
                onChange={e => onChange('applicationId', parseInt(e.target.value))}
            />
            <label>Destination Environment</label>
            <DropDown
                name='destConnectionString'
                value={dropDownOptions[0]}
                payload={dropDownOptions}
                onChange={onChange}
                apptype= {true}
            />
            <label>Destination App ID</label>
            <AInput
                type='number'
                name=''
                placeholder='Enter your Destination App ID'
                onChange={e => onChange('destApplicationId', parseInt(e.target.value))}
            />
            <label>User Id</label>
            <AInput
                type='number'
                name=''
                placeholder='Enter your User ID'
                onChange={e => onChange('userId', parseInt(e.target.value))}
            />
        </div>
        <CustomButton
            id='CustomButton'
            classes='submit'
            onClick={onSubmit}
            buttontext='Migrate'
        />
    </div>
  )
}

export default AddMigrationDetails;
