import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import axiosInstance from '../../../apis/axiosInstance';
import { addApplication, addApplicationFailed,
    addApplicationSuccess, fetchedLocaleDataFailed, fetchLocaleData, fetchLocaleDataSuccess } from './addApplication.actions';

export interface requestOptions {
    method: string;
    headers: HeadersInit;
    redirect: RequestRedirect;
}
export interface resultobject {
    localeResult: Array<object>,
    appResult: Array<object>,

}

function* localeData(action: PayloadAction<{}>) {

    /////// apptypes
    const appResponse = yield axiosInstance.get('/accounts/applications/v2/types');
    const appResult = yield appResponse;

    /////// locales
    const localeResponse = yield axiosInstance.get('/assist/api/locales/v3')
    const localeResult = yield localeResponse;

    if (localeResponse.status === 200 && appResult.status === 200) {
        let result: resultobject = { localeResult: [], appResult: [] };
        result.localeResult = localeResult.data;
        result.appResult = appResult.data;
        yield put(fetchLocaleDataSuccess(result));

    }
    else {
        yield put(fetchedLocaleDataFailed());
    }

}

export interface requestOptionSubmit {
    body: any
}

function* submitApplicationDetails(action: PayloadAction<{}>) {

    var addAppRequestOptions: requestOptionSubmit = {
        body: action.payload
    }
    try {
        const addAppResponse = yield axiosInstance.post('/accounts/applications/v3', addAppRequestOptions.body)

        if (addAppResponse && addAppResponse.status === 200) {
            yield put(addApplicationSuccess());
        }

        else {
            yield put(addApplicationFailed());
        }
    }
    catch (error) {
        yield put(addApplicationFailed());
    }
}
export const getLocaleDataEffect = [
    takeEvery([fetchLocaleData.type], localeData),
    takeEvery([addApplication.type], submitApplicationDetails)
];
