import React, { useEffect, useState } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import CustomButton from '../../common/button/button';
import '../analytics.css';
import { activitesDemoData } from '../../../utils/activitiesDemoData';
import { useDispatch, useSelector } from 'react-redux';
import { getTenantInfo } from '../../Tenant/TenantInfo/tenantInfo.selector';
import { selectAnalyticsData } from '../analytics.selector';
import { insertActivitiesData } from '../analytics.actions';
import { getFromDate, getToDate } from '../../../utils/utils';

const Activities = (props: any) => {
  const dispatch = useDispatch();

  // Modifying demo data according to the need
  const demoData ={ ...activitesDemoData,
    to: getToDate(),
    from:  getFromDate(),
    tenantId: useSelector(getTenantInfo).tenantId,
    appId: useSelector(getTenantInfo).appId,
    appType: useSelector(getTenantInfo).appName,
    tenantKey: useSelector(getTenantInfo).tenantKey}

  var updatedData = JSON.stringify(demoData, null, 5);

  const analyticsStateData = useSelector(selectAnalyticsData);
  const [activitiesData, setActivitiesData] = useState(updatedData);
  const [analyticsData, setAnalyticsData] = useState({ loading: false, message: ' ' });
  const [displayMsg, setDisplayMsg] = useState(false);

  useEffect(() => {
    setAnalyticsData(analyticsStateData);
  }, [analyticsStateData])

  const setData = () => {
    dispatch(insertActivitiesData(activitiesData));
    setDisplayMsg(true);
  }

  const closePopup = () => {
    setDisplayMsg(false);
  }

  return (
    <div style={{ width: '90%' }}>
      <CodeMirror
        value={activitiesData}
        onBeforeChange={(editor: any, data: any, value: any) => {
          setActivitiesData(value);
        }}
        onChange={(editor: any, data: any, value: any) => {
        }}
      />
      <div style={{ width: '10%', float: 'right' }}>
        <CustomButton
          buttontext={'Set Data'}
          onClick={setData}
        />
      </div>
      {!analyticsStateData.loading && displayMsg && <div className='display-message'>
        {analyticsData.message}
        <span className='closebtn' onClick={closePopup}>&times;</span>
      </div>}
    </div>
  );
};

export default Activities;
