import { createReducer } from '@reduxjs/toolkit';
import { putAppTypes, putLocales, submitTenants, tenantAdded, tenantAdditionFailed } from './newTenant.actions';

export interface AppTypes {
  appTypes: [];
  locales: [];
  requestLoading:boolean,
  alertMessage:string,
  tenantAdded:boolean
}


const initialState: AppTypes = {
  appTypes: [],
  locales: [],
  requestLoading: false,
  alertMessage:'',
  tenantAdded:false
}

export const newTenantReducer = createReducer(initialState,builder => {
  builder.addCase(putAppTypes, (state, action) => {
    return { ...state, appTypes: action.payload };
  });

  builder.addCase(putLocales, (state, action) => {
    return { ...state, locales: action.payload };
  })


  builder.addCase(submitTenants, (state, _) => {
    return { ...state,
      requestLoading:true,
      tenantAdded:false
    };
  })

  builder.addCase(tenantAdded, (state, _) => {
    return { ...state,
      requestLoading: false,
      alertMessage:'Tenant Added SuccessFully',
      tenantAdded:true };

  })

  builder.addCase(tenantAdditionFailed, (state, _) => {
    return { ...state,
      requestLoading: false,
      alertMessage:'Failed to add Tenant',
     };
  })

});