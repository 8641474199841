import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Environments } from '../../shared/types/type';
import DropDown from '../common/dropdown/dropdown';
import EnvironmentList from '../common/Environment/EnvironMentList';
import ModalButton from '../common/ModalButton/ModalButton';
import { PATHS } from '../../utils/path';
import { isEnvEmpty, isValidObject } from '../../utils/utils';
import { fetchAppTypes, fetchLocales, submitTenants } from './newTenant.actions';
import './newTenant.css';
import { getnewTenantData } from './newTenant.selector';

export interface localState {
    appTypeList: Array<object>,
    localeList: Array<object>,
    loading: boolean,
    provisioned: boolean,
    submitDisabled: boolean
}

export interface body {
    Name: string,
    appName: string,
    appTypeCode: string,
    environments: any,
    defaultLocaleId: number,
    playerDefaultLocaleId: number,
    email: string,
    fullName: string
    automation: {
        admin: {
            shouldcreate: boolean,
            url: string
        },
        client: {
            mac: string,
            windows: string
        }
    },
    client: {
        mac: string,
        windows: string
    },
    studio: {
        mac: string,
        windows: string
    },
    roles: any,
    timezone: string
}

const IinitialState: body = {
    Name: '',
    appName: '',
    appTypeCode: 'sfLight',
    environments: {},
    defaultLocaleId: 1,
    playerDefaultLocaleId: 1,
    email: '',
    fullName: '',
    automation: {
        admin: {
            shouldcreate: false,
            url: 'Automation Admin URL'
        },
        client: {
            mac: '<Auomation Mac Download URL>',
            windows: '<Automation Windows Downlaod URL>'
        }
    },
    client: {
        mac: '<Apty Client Mac Donwload URL>',
        windows: '<Apty Client Windows Download URL>'
    },
    studio: {
        mac: '<Apty Studio Mac Download URL>',
        windows: '<Apty Studio Windows Downlaod URL>'
    },
    roles: [1],
    timezone: 'UTC'
}

const LocalInitalState: localState = {
    appTypeList: [],
    localeList: [],
    loading: false,
    provisioned: false,
    submitDisabled: true
}



const NewTenant = (props: RouteComponentProps) => {

    const dispatch = useDispatch();
    const [state, setState] = useState(IinitialState);
    const [localState, setLocalState] = useState(LocalInitalState);
    const [showPopup, setShowPopup] = useState(false);

    const newTenantData = useSelector(getnewTenantData);
    /************** fetches app types and locales ******************/

    useEffect(() => {
        dispatch(fetchAppTypes());
        dispatch(fetchLocales());
    }, [dispatch]);

    useEffect(() => {
        setLocalState({ ...localState, appTypeList: newTenantData.appTypes });

    }, [newTenantData.appTypes]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setLocalState({ ...localState, localeList: newTenantData.locales });

    }, [newTenantData.locales]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(isValidObject(state) && isApiCallValid(state, state.environments)){
            setLocalState({
                ...localState,
                submitDisabled: false
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])


    /************* event handlers ***************/

    const handleSubmit = async () => {
        setLocalState({ ...localState, loading: true })
        var provBody: body = state
        dispatch(submitTenants(provBody));
        setLocalState({ ...localState, loading: false })
        setShowPopup(true);
    }

    const handleChange = (key: keyof body, value: string) => {

        let stateData = { ...state };

        stateData = {
            ...state,
            [key]: value
        }
        setState(stateData)
    }

    const isApiCallValid = (application: body, environments: Environments) => {
        let isEmptyObj = isEnvEmpty(environments)
        if (application.Name && application.appName && application.email && !isEmptyObj) {
            return true;
        }
        else {
            return false;
        }
    }

    const handleEnviromentChange = (event: ChangeEvent<HTMLInputElement>, id: number) => {

        const { name, value } = event.target;
        let envDetails = { ...state };
        let environmentList = envDetails.environments;

        if (!environmentList[id]) {
            environmentList[id] = {}
            environmentList[id]['order'] = id;
            environmentList[id][name] = value;
        }
        else {
            environmentList[id]['order'] = id;
            environmentList[id][name] = value;
        }
        isApiCallValid(envDetails, environmentList)
        setState({
            ...envDetails,
            environments: environmentList
        })

    }

    const deleteEnvironmentEntry = (id: number) => {
        setState(prevState => {
            delete prevState.environments[id]
            return prevState
        })
    }

    const closePopup = () => {
        setShowPopup(false);
        if (newTenantData.tenantAdded) {
            props.history.push(PATHS.BASE)
        }

    }

    return (
        <div className='form-container tenant-add-form'>
            <p className='heading'>New Tenant</p>

            <form method='post' onSubmit={handleSubmit}>
                {/******************* GENERAL SETTINGS  *********************/}

                <p className='section-title'>General Settings</p>

                <div className='input-container'>
                    <label htmlFor='tenantName'>Tenant Name</label>
                    <input

                        type='text'
                        id='tenantName'
                        name='Name'
                        value={state.Name}
                        onChange={e => handleChange('Name', e.target.value)}
                        placeholder='Tenant name'
                        required
                    />
                </div>

                <div className='input-container right'>
                    <label htmlFor='appName'>App name</label>
                    <input
                        className='input-custom'
                        type='text'
                        id='appName'
                        name='appName'
                        value={state.appName}
                        onChange={e => handleChange('appName', e.target.value)}
                        placeholder='App name'
                        required
                    />
                </div>

                <div className='full-width'>
                    <label>App Type code</label>
                    <DropDown
                        name='appTypeCode'
                        value={state.appTypeCode}
                        payload={localState.appTypeList}
                        onChange={handleChange}
                        apptype= {true}
                    />
                </div>

                <div className='input-container'>
                    <label htmlFor='defaultLocaleId'> Default Locale ID </label>
                    <DropDown
                        name='defaultLocaleId'
                        value={state.defaultLocaleId}
                        payload={localState.localeList}
                        onChange={handleChange}

                    />
                </div>

                <div className='input-container right'>
                    <label htmlFor='playerDefaultLocaleId'> Player Default Locale Id</label>
                    <DropDown
                        name={'playerDefaultLocaleId'}
                        value={state.playerDefaultLocaleId}
                        payload={localState.localeList}
                        onChange={handleChange}

                    />
                </div>


                {/******************* ENVIRONMENTS SETTINGS  *******************/}

                <p className='section-title'>Environments Setup</p>

                <EnvironmentList
                    handleEnviromentChange={handleEnviromentChange}
                    deleteEnvironmentEntry={deleteEnvironmentEntry}
                />


                {/********************* USER IDENTIFICATION  *******************/}

                <p className='section-title'>User Information</p>

                <div className='input-container full-width'>
                    <label htmlFor='email'> Email </label>
                    <input
                        type='email'
                        id='email'
                        name='email'
                        value={state.email}
                        onChange={e => handleChange('email', e.target.value)}
                        placeholder='Email'
                        required
                    />
                </div>

                <div className='input-container full-width'>
                    <label htmlFor='fullName'>Full Name</label>
                    <input
                        type='text'
                        id='fullName'
                        name='fullName'
                        value={state.fullName}
                        onChange={e => handleChange('fullName', e.target.value)}
                        placeholder='Name'
                        required
                    />
                </div>

                <div style={{ display: 'inline-flex' }}>

                    <ModalButton
                        loading={newTenantData.requestLoading}
                        classes='add-button'
                        onSubmit={handleSubmit}
                        theme='white'
                        buttonText='Submit'
                        MainText={<span>Are you sure You want to add tenant <strong>{state.Name}</strong></span>}
                        headerText='Add Tenant'
                        disabled={localState.submitDisabled}


                    />

                    {showPopup && newTenantData.alertMessage && !newTenantData.requestLoading && <div className='error-message'>
                        {newTenantData.alertMessage}
                        <span className='closebtn' onClick={closePopup}>&times;</span>

                    </div>}
                </div>

            </form>
        </div>

    )
}

export default NewTenant;