import { createReducer } from '@reduxjs/toolkit';
import { AnalyticsState } from '../../shared/types/type';
import { activitiesDataAddedFailed, activitiesDataAddedSucceed,
     assistDataAddedSucceed, assistsDataAddedFailed, insertActivitiesData,
      insertAssistData, insertInsightsData, insightsDataAddedFailed, insightsDataAddedSucceed } from './analytics.actions';

const initialState: AnalyticsState = {
    loading: false,
    data: '',
    message:''
}

export const analyticsReducer =  createReducer(initialState,builder => {

    builder.addCase(insertInsightsData, (state, action) => {
        return {
            ...state,
            loading: true
        };
    });
    builder.addCase(insightsDataAddedSucceed, (state, action) => {
        return {
            ...state,
            loading: false,
            message: 'Data Added Successfully'
        };
    });
    builder.addCase(insightsDataAddedFailed, (state, action) => {
        return {
            ...state,
            loading: false,
            message: 'Issue while adding Data'
        };
    });

    builder.addCase(insertAssistData, (state, action) => {
        return {
            ...state,
            loading: true
        };
    });
    builder.addCase(assistDataAddedSucceed, (state, action) => {
        return {
            ...state,
            loading: false,
            message: 'Data Added Successfully'
        };
    });
    builder.addCase(assistsDataAddedFailed, (state, action) => {
        return {
            ...state,
            loading: false,
            message: 'Issue while adding Data'
        };
    });

    builder.addCase(insertActivitiesData, (state, action) => {
        return {
            ...state,
            loading: true
        };
    });
    builder.addCase(activitiesDataAddedSucceed, (state, action) => {
        return {
            ...state,
            loading: false,
            message: 'Data Added Successfully'
        };
    });
    builder.addCase(activitiesDataAddedFailed, (state, action) => {
        return {
            ...state,
            loading: false,
            message: 'Issue while adding Data'
        };
    });

});