import React from 'react';
import UrlConfig from './UrlConfig';
const UrlConfigList = (props: any) => {

    return (
        <div className='url-config-container'>
            <div className='url-config-header'>
                <input
                    className='config-input'
                    value={props.iframeUrls}
                    type='text'
                    name='inputUrl'
                    onChange={e => props.onChange('inputUrl', e.target.value)}
                    placeholder='https://url.com'
                />
                <i className='fa fa-plus' onClick={props.addUrl}></i>
            </div>
            <UrlConfig
                urlList={props.urlList}
                deleteUrl={props.deleteUrl}
            />
            {/* will come from parent */}
        </div>
    )

};

export default UrlConfigList;