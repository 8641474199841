import {
  combineReducers,
  configureStore,
  getDefaultMiddleware
} from '@reduxjs/toolkit';
import { SagaMiddleware } from 'redux-saga';
import { Login, UserReducer } from '../components/loginComponents/login.reducer';
import { ApplicationState, configApplicationReducer } from '../components/configureComponents/config.reducer';
import { AppTypes, newTenantReducer } from '../components/NewTenant/newTenant.reducer';
import { localeData, localeDataReducer } from '../components/Tenant/AddAplication/addApplication.reducer';
import { TenantInfo, tenantInfoReducer } from '../components/Tenant/TenantInfo/tenantInfo.reducer';
import { Tenant, tenantReducer } from '../components/Tenant/TenantList/tenant.reducer';
import { roleListReducer, RolesList } from './../components/AddUser/adduserReducer';
import { injectedHookReducer, InjectedState } from './../components/injectedHook/injectedhook.reducer';
import { UserDetails, userDetailsReducer } from './../components/UserList/UserList.reducer';
import { analyticsReducer } from '../components/analyticsComponents/analytics.reducer';
import { AnalyticsState } from '../shared/types/type';


export interface AppState {
   user: Login;
   tenant:Tenant;
   userDetails:UserDetails
   tenantInfo:TenantInfo;
   localeData:localeData;
   newTenantData:AppTypes;
   applicationInfo:ApplicationState;
   injectedHookData:InjectedState;
   roleListData:RolesList;
   analyticsData:AnalyticsState
}

const middleware = [...getDefaultMiddleware({ thunk: false })];

function reducers(): any {
    return combineReducers<AppState>({
      user: UserReducer,
      tenant:tenantReducer,
      tenantInfo:tenantInfoReducer,
      localeData:localeDataReducer,
      newTenantData: newTenantReducer,
      applicationInfo:configApplicationReducer,
      injectedHookData:injectedHookReducer,
      roleListData:roleListReducer,
      userDetails:userDetailsReducer,
      analyticsData:analyticsReducer
    })
  }

export function createStore(sagaMiddleware: SagaMiddleware): any {
  middleware.push(sagaMiddleware);
  return configureStore({
    reducer: reducers(),
    devTools: true,
    middleware
  });
}
