import React from 'react';
import CustomLoader from '../loader/component.loader';
import './button.css';

interface Props {
    classes?: string;
    id?: string
    disabled?: boolean;
    loading?: boolean;
    buttontext: string;
    onClick?: () => void,
}

const CustomButton = (props: Props) => {
    let classeslist: string = '';
    if (props.classes) {
        classeslist = props.disabled ? ` disabled ${props.classes}` : props.classes;
    }
    else {
        classeslist = props.disabled ? 'disabled custom-button' : 'custom-button'
    }
    return (
        <div
            id={props.id}
            className={classeslist}
            onClick={props.onClick}

            tabIndex={-1}
        >
            {
                props.loading ? (<CustomLoader buttonSpinner={true} />) : (<span className={'button-text'}>{props.buttontext}</span>)
            }
        </div>
    );
};

export default CustomButton;