import { InjectedHookType } from '../../shared/types/type';
import { PayloadAction } from '@reduxjs/toolkit';
import { takeEvery, put } from 'redux-saga/effects';
import axiosInstance from '../../apis/axiosInstance'
import { fetchInjectedHook, fetchInjectedHookSuccess,
    fetchInjectedHookFailed, saveInjectedHookFailed,
    saveInjectedHookSuccess, saveInjectedHook } from './injectedHooks.actions';

function* getInjectedData(action: PayloadAction<number>) {
    try {
        const response = yield axiosInstance.get(`/assist/api/injected-hook/v1/tenant/${action.payload}`);
        var injectedData = yield response.data;
        if (response.status === 200) {
            yield put(fetchInjectedHookSuccess(injectedData.data))
        }
    }
    catch (error) {
        yield put(fetchInjectedHookFailed())
    }
}

function* saveInjectedHookData(action: PayloadAction<InjectedHookType>) {
    try {
        const response = yield axiosInstance.put(`/assist/api/injected-hook/v1/tenant/${action.payload.tenantId}`, action.payload.script);
        if (response.status === 200) {
            yield put(saveInjectedHookSuccess())
        }
    }
    catch (error) {
        yield put(saveInjectedHookFailed())
    }
}
export const InjectedHookEffects = [
    takeEvery([fetchInjectedHook.type], getInjectedData),
    takeEvery([saveInjectedHook.type], saveInjectedHookData)
];