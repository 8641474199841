import { createAction } from '@reduxjs/toolkit';

export const insertAssistData=createAction<string>('SET_ASSIST_DATA');
export const assistDataAddedSucceed = createAction('ASSIST_DATA_ADDED_SUCCEED')
export const assistsDataAddedFailed = createAction('ASSIST_DATA_ADDED_FAILED')

export const insertInsightsData=createAction<string>('INSERT_INSIGHTS_DATA');
export const insightsDataAddedSucceed = createAction('INSIGHTS_DATA_ADDED_SUCCEED')
export const insightsDataAddedFailed = createAction('INSIGHTS_DATA_ADDED_FAILED')

export const insertActivitiesData=createAction<string>('SET_ACTIVITIES_DATA');
export const activitiesDataAddedSucceed = createAction('ACTIVITIES_DATA_ADDED_SUCCEED')
export const activitiesDataAddedFailed = createAction('ACTIVITIES_DATA_ADDED_FAILED')
