import { createAction } from '@reduxjs/toolkit';
import { loginDetails } from '../../shared/types/type';
interface Token{
    success:false,
    token: '',
}
interface Error
{
message:string
}
export const userLogin = createAction<loginDetails>('USER_LOGIN_INIT');
export const userLoggedIn = createAction<Token>('USER_LOGIN_SUCCESS');
export const userLoggedInFailed=createAction<Error>('USER_LOGIN_FAILED');