import axios from 'axios';


function getInstanceUrl(): any {
  return localStorage.getItem('instanceUrl');
}

const axiosInstance = axios.create({
  baseURL: getInstanceUrl(),
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use(function (config: any) {
  const token = localStorage.getItem('auth-token');
  config.baseURL = localStorage.getItem('instanceUrl')
  if (config.url && config.url.includes('feature-flag.json')) {
    var URL = localStorage.getItem('instanceUrl');
    URL = URL && URL.replace('://', '://client.');
    config.baseURL = URL;
  }
  else {
    config.baseURL = localStorage.getItem('instanceUrl')
  }
  config.headers.Authorization = token;
  return config;
});

export default axiosInstance;

