import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import CustomLoader from '../common/loader/component.loader';
import { changeRoute } from '../../shared/helpers/routing.helper';
import { PATHS } from '../../utils/path';
import { fetchLocaleData } from '../Tenant/AddAplication/addApplication.actions';
import { selectLocaleData } from '../Tenant/AddAplication/addApplication.selector';
import { fetchingApplication } from '../configureComponents/config.actions';
import '../configureComponents/Config.css';
import { selectApplicationsDetails } from '../configureComponents/config.selector';
import AnalyticsNavbar from './analyticsNavbar';
import Assist from './Assist/assist';
import Activities from './Activities/activities';
import Insights from './Insights/insights';
interface MatchParams {
  tenantName?: string;
  appName?: string;
  tenantId?: string;
  appId?: string;
}

interface Props extends RouteComponentProps<MatchParams> {}
const AnalyticsConfig = (props: Props) => {
  const dispatch = useDispatch();
  const applicationInfo = useSelector(selectApplicationsDetails);
  const getLocaleData: any = useSelector(selectLocaleData);
  let payload = {
    tenantId: props.match.params.tenantId,
    appId: props.match.params.appId,
  };

  const [selectedNavbar, setSelectedNavbar] = useState('Assist');
  useEffect(() => {
    dispatch(fetchingApplication(payload));
    dispatch(fetchLocaleData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Back = () => {
    changeRoute(PATHS.BASE);
  };

  if (applicationInfo.loadingApplication || getLocaleData.loading) {
    return <CustomLoader />;
  }
  return (
    <div className='config-container'>
      <Router>
        <AnalyticsNavbar
          selectedNavbar={selectedNavbar}
          Back={Back}
          setSelectedNavbar={setSelectedNavbar}
          tenantName={props.match.params.tenantName}
          tenantId={props.match.params.tenantId}
          appName={props.match.params.appName}
          appId={props.match.params.appId}
          navData={[
            {
              navbarListData: 'Assist',
              class: 'Assist',
              toUrl: 'assist',
              id: 'menu-item1',
            },
            {
              navbarListData: 'Activities',
              class: 'Activities',
              toUrl: 'activities',
              id: 'menu-item2',
            },
            {
              navbarListData: 'Insights',
              class: 'Insights',
              toUrl: 'insights',
              id: 'menu-item3',
            },
          ]}
        />
        <Switch>
          <Route
            path={PATHS.ASSIST}
            render={props => <Assist data={payload} />}
          />
          <Route
            path={PATHS.ACTIVITIES}
            render={props => <Activities data={payload} />}
          />
          <Route
            path={PATHS.INSIGHTS}
            render={props => <Insights data={payload} />}
          />
        </Switch>
      </Router>
    </div>
  );
};

export default withRouter(AnalyticsConfig);
