import { userLogin, userLoggedIn,userLoggedInFailed } from './login.action';
import { loginDetails } from '../../shared/types/type';
import { createReducer } from '@reduxjs/toolkit';
export interface Login {
     isLoggedIn: boolean;
     isLogin:boolean;
     loading:boolean;
     token:string,
     UserDetails:loginDetails;
     errorMessage:string,
  }
  const userDetails:loginDetails={
    email: '',
    password: '',
    source: 'ADMIN',
    instanceUrl: 'https://development.apty.io',
    role:''

  }
  const initialState: Login = {
    isLoggedIn:false,
    loading:false,
    isLogin:false,
    UserDetails:userDetails,
    token:'',
    errorMessage:''
    }

export const UserReducer = createReducer(initialState,builder => {
  builder.addCase(userLogin, (state, action) => {
    return {
      ...state,
      isLogin: true,
      loading:true,
      errorMessage:'',
      UserDetails:action.payload,
    };
  });

  builder.addCase(userLoggedIn, (state, action) => {
    return {
      ...state,
      isLoggedIn: true,
      errorMessage:'',
      loading:false,
      isLogin: false,
      token:action.payload.token,

    };
  });
  builder.addCase(userLoggedInFailed, (state, action) => {
    return {
      ...state,
      isLoggedIn: false,
      loading:false,
      isLogin: false,
      UserDetails:userDetails,
      errorMessage: action.payload.message
    };
  });
});
