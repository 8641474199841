import React from 'react';
import UnpublishIcon from '../../../assets/images/unpublish_icon.svg';
import PublishIcon from '../../../assets/images/publish_icon.svg';
import RevertIcon from '../../../assets/images/Revert_icon.svg';

interface Props {
    publishStatus: string,
    publishedCustomJs: (env: number, status: string) => void,
    env: number,
    environmentName: string,
    disabled:boolean
}
const PublishStatusItem = (props: Props) => {

    return (

        <div className='custom-publish-popup-staging'>
            <span className='publish_menu_env'>
                {props.environmentName}
            </span>
            <span
                className={props.publishStatus === 'UP_TO_DATE' || props.disabled ? 'publish_icon_disabled' : 'publish_icon'} >
                <img src={PublishIcon} alt='Publish' />
                <span
                    className='publish-title'
                    onClick={() => props.publishedCustomJs(props.env, 'UP_TO_DATE')}>Publish Latest</span>
            </span>
            <div
                className={props.publishStatus === 'NOT_PUBLISHED'  || props.disabled ? 'publish_icon_disabled' : 'publish_icon'}  >
                <img src={UnpublishIcon} alt='Publish' />
                <span
                    className={'publish-title'}
                    onClick={() => props.publishedCustomJs(props.env, 'NOT_PUBLISHED')}>UnPublish</span>
            </div>
            {props.env === 1 && <span
                className={(props.publishStatus === 'NOT_PUBLISHED' || props.publishStatus === 'ROLLBACK' || props.disabled)  ? 'publish_icon_disabled' : 'publish_icon'}>
                <img src={RevertIcon} alt='Publish' />
                <span
                    className={'publish-title'}
                    onClick={() => props.publishedCustomJs(props.env, 'ROLLBACK')}>Revert</span>
            </span>}
        </div>
    )

}
export default PublishStatusItem;