export interface loginDetails {
    email:string;
    password: string;
    source: string;
    instanceUrl: string;
    role: string;
  }

export interface MigrationData {
  applicationId: number ,
  destApplicationId: number,
  sourceConnectionString: string | undefined,
  destConnectionString: string | undefined,
  userId: number
}

export type MigrationDataCheck = keyof MigrationData
export interface  authRequestOptions {
    method: string;
    headers: HeadersInit;
    body: string;
    redirect: RequestRedirect;
}

export interface Application {
  id: number;
  name: string;
  appKey?: string;
  defaultLocaleId: number;
  environments: Environments;
  script?: string;
  customJSPublishingStatus: EnvironmentsStatus;
  segmentJSPublishingStatus: EnvironmentsStatus;
  config?: ApplicationConfig;
  hardConfig?: SerializedApplicationHardConfig;
  preProdIFrameUrls?: string[];
  prodIFrameUrls?: string[];
  defaultEnvForEditor: number;
  appTypeId?: number;
  appTypeCode?: string;
  userIdentification: UserTrackingConfig;
  playerDefaultLocaleId: number;
  restrictURLs?: string[];
  isActive?: boolean;
  errorConfig?: ErrorConfig;
  tenantId?: string;
}
export interface customPublishTypes
{
  status:string,
  environment:number,
  tenantId:number
  appId?:number
}

export type Environments = {
  [key in string]: Environment
};
export interface Environment {
  order: number;
  name: string;
  url: string;
  enablePlayer: boolean;
}

export type EnvironmentsStatus = {
  [key in string]: PublishStatusType;
};

export type PublishStatusType = 'NOT_PUBLISHED' | 'UP_TO_DATE' | 'OUTDATED' | 'ROLLBACK';
export interface ApplicationConfig {
  applicationKey: string;
  customConfig: ApplicationCustomConfigTypes;
  enableAptyAssistClient: boolean;
  segmentAdvancedJS: string;
  thirdPartyAnalytics?: ThirdPartyAnalytics;
}


export interface ApplicationCustomConfigTypes {
  ignoreSelectorIdFunction?: string;
  ignoreSelectorClassFunction?: string;
  ignoreSelectorAttributeFunction?: string;
  userIdRefreshConfig: RefreshConfigTypes;
  isDetectTopWindowDOMChanges: boolean;
  containerScoreThreshold: number;
  attributePriority:string[]
}

export interface FunctionConfigTypes
{
  ignoreSelectorIdFunction?: string;
  ignoreSelectorClassFunction?: string;
  ignoreSelectorAttributeFunction?: string;

}
export interface CustomConfigFunction
{
  ignoreSelectorIdFunction?: string;
  ignoreSelectorClassFunction?: string;
  ignoreSelectorAttributeFunction?: string;
}
export interface ThirdPartyAnalytics {
  type: string;
  enabled: boolean;
  requestType: RequestType;
  url: string;
  headers: string;
  body: string;
  eventKey: string;
  encodeData: boolean;
  eventNames: string[];
  keysMap: string;
}
export interface RefreshConfigTypes {
  selectors: string[];
  currentUrls: string[];
}

export type RequestType = 'GET' | 'POST' | 'PUT' | 'DELETE';

export interface SerializedApplicationHardConfig {
  key: string;
  applicationName: string;
  isApplication?: string;
  ignoreSelectorIdFunction?: string;
  ignoreSelectorClassFunction?: string;
  ignoreSelectorAttributeFunction?: string;
  ignoreSVG?: boolean;
  forceScrollBehaviour?: boolean;
  extractUserId?: string;
  atributePriority?: string[];
  overrideHitTest?: boolean;
  userIdRefreshConfig?: RefreshConfigTypes;
}
export interface UserTrackingConfig {
  type: UserIdentificationType;
  value?: string;
}
export type UserIdentificationType = keyof typeof UserIdentification;
export enum UserIdentification {
  letznavId = 1,
  ApplicationID = 2,
  Custom = 3
}
export interface ErrorConfig {
  errorLabelScript: string;
  errorDetectionScript: string;
  errorPreference: any;
}

 export const PUBLISHING_PRODUCTION_ENV = 1;

 export interface InjectedHookType{
   script:scriptTypes,
   tenantId:number,
 }
 export interface scriptTypes{
   script:string
 }

 export interface Appredirect {
  tenantName:string,
  appName:string,
  tenantId:number,
  appId:number,
  tenantKey: string
 }


 export interface featuresFlag {
  contentCopyAcrossApplications: ContentCopyAcrossApplications;
  okr: ContentCopyAcrossApplications;
  urlRegex: ContentCopyAcrossApplications;
  contentLength: ContentLength;
  publishingSystem: ContentCopyAcrossApplications;
  publishingEnvironments: ContentLength;
  workflowAutopilot: ContentCopyAcrossApplications;
  thirdPartyAnalytics: ContentCopyAcrossApplications;
  detectLocaleOnDomChange: ContentCopyAcrossApplications;
  clientVersion: ContentLength;
  disableInsightsAnalytics: ContentCopyAcrossApplications;
  disableAssistAnalytics: ContentCopyAcrossApplications;
  modifySuccessMessage: ContentCopyAcrossApplications;
  disableServerCalls: ContentCopyAcrossApplications;
  invokeStepAfterFramesUpdate: ContentCopyAcrossApplications;
  errorInsightsConfig: ContentCopyAcrossApplications;
  themesV2: ContentCopyAcrossApplications;
  flowVoiceOver: ContentCopyAcrossApplications;
  draggablePlayer: ContentCopyAcrossApplications;
  announcementCTA: ContentCopyAcrossApplications;
  zindexAppConfig: ContentCopyAcrossApplications;
  editorExtensionDownload: ContentCopyAcrossApplications;
  enableFrameCheck: ContentCopyAcrossApplications;
}

interface ContentLength {
  key: string;
  status: string;
  type: string;
}

interface ContentCopyAcrossApplications {
  key: string;
  status: boolean;
  type: string;
}


export interface  UserPayloadTypes {
  email: string;
  fullName: string;
  roles: number[];
  isSSO: boolean;
  isActive: boolean;
  SSOId?: any;
  applications: any[];
  isEmailVerified: boolean;
  tenantId: number;
  id:number
}
export interface UserDetailsTypes {
  id: number;
  fullName: string;
  email: string;
  tenantId: number;
  tenantKey: string;
  PasswordResetExp: Date;
  AccountLockedDate: Date;
  isEmailVerified: boolean;
  roles: number[];
  roleName: string[];
  applications: number[];
  isActive: boolean;
  is_deleted: boolean;
  redirect_url: string;
  permissions: number[];
  createdBy: number;
  updatedBy: number;
  isResetExpired: boolean;
}

export interface AnalyticsData {
  data: any
}
export interface AnalyticsState {
  loading: boolean;
  data: string;
  message: string
}