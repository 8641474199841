import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNewUser, fetchRoleList } from './adduser.actions';
import { getRoleListData } from './adduser.selector'

import './adduser.css'
import DropDown from '../common/dropdown/dropdown';
import ModalButton from '../common/ModalButton/ModalButton';
import { UserPayloadTypes } from '../../shared/types/type';
import AInput from '../common/Ainput/Ainput';
import { isEmailValid } from '../../utils/utils';

interface Props {
    tenantID: number,
}

const AddUser = (props: Props) => {
    const dispatch = useDispatch();
    const RoleListData: any = useSelector(getRoleListData);
    let initialUserDetails: UserPayloadTypes = {
        roles: [5],
        fullName: '',
        email: '',
        tenantId: props.tenantID,
        id: 1,
        applications: [],
        isEmailVerified: false,
        isSSO: false,
        isActive: true,
        SSOId: null,
    }
    const [userDetails, setUserDetails] = useState(initialUserDetails);
    const [showAlertMessage, setShowAlertMessage] = useState(false);
    useEffect(() => {
        dispatch(fetchRoleList(props.tenantID))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const closePopup = () => {
        setShowAlertMessage(false)
    }
    const handleOnchange = (key: string, value: string) => {

        if (key === 'roles') {
            setUserDetails({
                ...userDetails,
                [key]: [parseInt(value)]
            })
        }
        else {
            setUserDetails({
                ...userDetails,
                [key]: value
            })
        }


    }

    const onSubmitAppDetails = () => {
        dispatch(addNewUser(userDetails));
        setShowAlertMessage(true)
    }
    return (
        <div>
            <form>
                <div className='user-status'>
                    <label>STATUS</label>
                    <input type='checkbox'
                        checked={userDetails.isActive} />
                    <span className='user-active-title'>User Active</span>
                </div>
                <div>
                    <label>User Name</label>
                    <input type='text'
                        value={userDetails.fullName}
                        onChange={e => handleOnchange('fullName', e.target.value)} />

                </div>
                <div>
                    <label> User Email</label>
                    <AInput type='email' name=''
                        value={userDetails.email}
                        error={userDetails.email ? !isEmailValid(userDetails.email) : false}
                        onChange={e => handleOnchange('email', e.target.value)}
                        errorMessage='Please enter Valid Email Id' />

                </div>
                <div>
                    <label>
                        User Role:
                </label>
                    <DropDown
                        name='roles'
                        payload={RoleListData?.roleList['Payload']}
                        onChange={handleOnchange} />
                </div>

            </form>
            <div className='addUser-button'>
                <ModalButton
                    classes={'config-submit-button'}
                    theme='white'
                    loading={RoleListData?.submitLoading}
                    headerText='Add Users'
                    MainText={<span>You are trying to add user <strong>
                        {userDetails.fullName} </strong> with email Id: <strong>
                            {userDetails.email}</strong> </span>}
                    buttonText='Submit'
                    disabled={(!userDetails.fullName || !userDetails.email) || !isEmailValid(userDetails.email)}
                    onSubmit={onSubmitAppDetails}
                />
            </div>
            { showAlertMessage && RoleListData.message && !RoleListData.submitLoading && <div className='error-message'>
                {RoleListData.message}
                <span className='closebtn' onClick={closePopup}>&times;</span>

            </div>}
        </div>
    )
}

export default AddUser;