import React from 'react';
import { RouteComponentProps } from 'react-router';
import {   Router, Route } from 'react-router-dom';
import routes from './routing/routes';
import { browserRoutingHistory } from './shared/helpers/routing.helper';
interface Props extends RouteComponentProps  {
}
function App() {

  return (
      <Router history={browserRoutingHistory}>

        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={
                ((props: Props) => {
                  return (
                    <route.component {...props} />
                  );
                })
              }
            />
          );
        })}
      </Router>

  );
}

export default App;
