import { createAction } from '@reduxjs/toolkit';

// Locale data fetched action

export const fetchLocaleData = createAction('FETCH_LOCALE_DATA_INIT');
export const fetchLocaleDataSuccess = createAction<any>('FETCH_LOCALE_DATA_SUCCESS');
export const fetchedLocaleDataFailed = createAction('FETCH_LOCALE_DATA_FAILED');

// add application fetched action

export const addApplication = createAction<object>('ADD_APPLICATION');
export const addApplicationSuccess = createAction('ADD_APPLICATION_SUCCESS');
export const addApplicationFailed = createAction('ADD_APPLICATION_FAILED');
