import React from 'react';

interface Props {
    payload: Array<object>,
    className?: string
    name?: string,
    value?: any,
    selected?: string,
    apptype?: boolean

    onChange?: (key: any, value: string, optional?: string) => void
}
const DropDown = (props: Props) => {
    return (
        <select {...props} onChange={e => props.onChange && props.onChange(props.name, e.target.value)}
            value={props.selected}>
            <option value='' disabled>-- Select an option --</option>
            {(props.payload || []).map((item: any, i) => (
                <option
                    key={i}
                    defaultValue={props.apptype ? item.code : parseInt(item.id)}
                    value={props.apptype ? item.code : parseInt(item.id)}
                >
                    {item.name}

                </option>
            ))}
        </select>

    );
};

export default DropDown;