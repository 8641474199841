import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Appredirect } from '../../../shared/types/type';
import CustomLoader from '../../common/loader/component.loader';
import { changeRoute } from '../../../shared/helpers/routing.helper';
import TenantCard from './component.tenant';
import { fetchingTenant } from './tenant.actions';
import { getTenantList } from './tenant.selector';
import { setAppData} from '../TenantInfo/tenantInfo.actions';

const TenantList = () => {
    const rootRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const allTenants = useSelector(getTenantList);
    let tenants: Array<object> = [];

    const [tenantList, setTenantList] = useState(tenants)
    const [search, setSearch] = useState('');
    useEffect(() => {
        if (allTenants.tenantList && !search) {
            setTenantList(allTenants.tenantList)
        }
    }, [allTenants, search])

    function redirect(appData: Appredirect, redirectUrl:string) {
        localStorage.setItem('tenantName', appData.tenantName)
        localStorage.setItem('appName', appData.appName)

        dispatch(setAppData(appData));
        changeRoute(`/config/${appData.tenantName}/${appData.tenantId}/${appData.appName}/${appData.appId}/${redirectUrl}`)
    }
    useEffect(() => {
        dispatch(fetchingTenant())
    }, [dispatch])


    const onClose = () => {
        const myElement = rootRef?.current;
        if (myElement) {
            myElement.style.filter = 'none'
        }
        dispatch(fetchingTenant())

    };
    const onOpen = () => {
        const myElement = rootRef?.current;
        if (myElement) {
            myElement.style.filter = 'blur(40px)';
        }
    };

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        var value = event.target.value
        setSearch(value)
        searchTenant(value);
    }

    const searchTenant = (searchValue: string) => {

        let tenantsList = allTenants.tenantList.filter((value: any) => {
            return value.tenantName.toLowerCase().includes(searchValue.toLowerCase())
        })
        if (tenantsList.length <= 0) {
            let elemnt = document.getElementById('no-search-results')
            if (elemnt) {
                elemnt.style.display = 'block'
            }
        }
        else {
            let elemnt = document.getElementById('no-search-results')
            if (elemnt) {
                elemnt.style.display = 'none'
            }

        }
        setTenantList(tenantsList);
    }
    return(
        <div className='tenant-list-container' ref={rootRef}>

            { allTenants.loading ? <CustomLoader /> :
                <>
                    <input type='text' name='search' placeholder='Search' className='search-input'
                        onChange={handleSearch} />
                    <div id='no-search-results' style={{ display: 'none' }}>
                        No matching results!!!
                    </div>

                    <div className='tenant-card-list-container' >
                        {tenantList && tenantList.map((tenant: any) => {
                            return (
                                <div id='tenant-card' key={tenant.tenantID}>
                                    <TenantCard
                                        appList={tenant.Apps}
                                        tenantKey={tenant.tenantKey}
                                        tenantName={tenant.tenantName}
                                        tenantID={tenant.tenantID}
                                        onClose={onClose}
                                        onOpen={onOpen}
                                        redirect={redirect}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </>}
        </div>
    )

}


export default TenantList;