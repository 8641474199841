import { PUBLISHING_PRODUCTION_ENV, Environments, Environment } from '../shared/types/type';
import { Application } from '../shared/types/type';

export function IsLoggedIn(): boolean {

  let authToken = localStorage.getItem('auth-token')
  if (hasValue(authToken)) {
    return true;
  }
  return false;
}
export function hasValue(v: any): boolean {
  if (typeof v !== 'undefined' && v && v !== '') {
    return true;
  }
  return false;
}

export const changeUrl = (url: any) => {
  localStorage.setItem('instanceUrl', url)
}

export const isEmpty = (value: string) => {

  value = value || '';
  return !value || !(value.trim && value.trim());
};

export const isEmailValid = (emailId: string) => {
  const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (reg.test(emailId) === false) {
    return false;
  }

  return true;
};
export const LINE_BREAK_REGEX = /(\r\n|\n|\r)/gm;

export const transformApplicationToSave = (app: Application) => {
  return {
    id: app.id,
    name: app.name,
    tenantId: app.tenantId && parseInt(app.tenantId),
    environments: app.environments,
    appTypeCode: app.appTypeCode,
    config: app.config,
    options: {
      script: app.script,
      errorConfig: app.errorConfig,
      userIdentification: app.userIdentification,
      preProdIFrameUrls: app.preProdIFrameUrls || [],
      prodIFrameUrls: app.prodIFrameUrls || [],
      restrictURLs: app.restrictURLs || [],
      appKey: app.appKey,
      defaultEnvForEditor: +app.defaultEnvForEditor || PUBLISHING_PRODUCTION_ENV,
    },
    payload: {
      environments: app.environments,
      appTypeCode: app.appTypeCode,
      appTypeId: app.appTypeId,
      defaultLocaleId: app.defaultLocaleId,
      playerDefaultLocaleId: app.playerDefaultLocaleId,
      isActive: app.isActive
    },
  };
}

export function isEnvEmpty(obj: Environments): boolean {

  if (Object.keys(obj).length === 0) {
    return true;
  }
  for (var key in obj) {
    var env: any = obj[key];
    if (Object.keys(env).length === 3) {
      for (var element in env) {

        if (!env[element] || (typeof env[element] === 'string' && !env[element].trim())) {
          return true;
        }
      }
    }
    else {
      return true;
    }

  }
  return false;
}



/**
 * returns true when envs have empty values else false
 */
export function isEnvironmentsValid(envs: Environments, isRegexEnabled: boolean): boolean {

  const keys = Object.keys(envs);
  for (const key of keys) {
    const env = envs[key] as Environment;
    if (!env.name?.trim().length) {
      return true;
    }
    if (isRegexEnabled) {
      if (!env.url?.trim().length) {
        return true;
      }

    }
    else {
      return !validURL(env.url)
    }
  }
  return false;
}
/**
 *
 * Return true if string is valid url else false
 */
function validURL(str: string): boolean {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

 type  EnvironmentTypes= {
  [key in string]: string
}

const EnvironmentListData:EnvironmentTypes = {
  'Development': 'https://development.apty.io', 'QA': 'https://qa.apty.io',
  'Staging': 'https://staging.apty.io', 'Labs': 'https://labs.apty.io', 'Production': 'https://app.apty.io'
}

export function getKeyByValue(value: string) {
  return Object.keys(EnvironmentListData).find(key => EnvironmentListData[key] === value);
}


export const isValidObject = (inputObject: any) => {
  return !Object.keys(inputObject).some(key => !inputObject[key])
}

var currentDate = new Date().getDate();
var currentMonth = new Date().getMonth();
var currentYear = new Date().getFullYear();

export function getToDate(){
  return `${currentYear}-${currentMonth + 1}-${currentDate}`;
}

export const getFromDate = () =>{
  if(currentMonth < 3){
    return `${currentYear}-${currentMonth + 10}-${currentDate}`;
  }
  else {
     return `${currentYear}-${currentMonth -2}-${currentDate}`;
  }
}