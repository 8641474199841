import React, { Fragment, useState } from 'react';
import './accordian.css';
import plusIcon from '../../assets/images/down-arrow.png';
import minusIcon from '../../assets/images/up-arrow.png';
import TenantInfo from '../Tenant/TenantInfo/component.tenantinfo';
import CustomPopup from '../common/CustomPopUp/CustomPopup';


interface ComponentDataType {
    tenantName: string,
    tenantKey: string,
    tenantID: string,
    Apps: ApplicationType[]

};
interface ApplicationType {
    appName: string,
    appID: String

}


export const Accordian = (props: ComponentDataType) => {
    const onCancel = (submit: boolean) => {
        setShowTenantInfo(false);
    }
    const [showInfo, setShowInfo] = useState(false)
    const [showTenantInfo, setShowTenantInfo] = useState(false);
    const { tenantName, tenantKey, Apps, tenantID } = props;
    return (
        <Fragment>
            <CustomPopup
                footer={null}
                title={'Features Flag'}
                visible={showTenantInfo}
                closable={true}
                maskClosable={false}
                keyboard={false}
                hideCross={false}
                modalClassName='add-application-container'
                onCancel={() => onCancel(false)}
                closeCallback={() => onCancel(false)}
            >
                <TenantInfo tenantName={tenantName} tenantKey={tenantKey} />
            </CustomPopup>


            <div className='accordian-container'>
                <div className='acoordian-wrapper'>
                    <div className='tenant-info'>
                        <div className='title-class tenant-id'>{tenantID}</div>
                        <div className='title-class font-weight tenant-key'>{Apps.length}</div>
                        <div className='title-class  tenant-name-class'>{tenantName}</div>
                    </div>

                    {/* <div>
                        <span onClick={() => { setShowTenantInfo(true) }}><i title='Tenant info' className='fa fa-info-circle'></i></span>
                    </div> */}
                    <div className='arrowkey-wrapper'>
                    <img alt ='toggle' src={showInfo ? minusIcon : plusIcon}
                    className='arrowKey' onClick={() => { setShowInfo(!showInfo) }} />
                    </div>

                </div>


                {
                    showInfo &&
                    <div className='app-container '>
                        <table className='applications-info'>
                            <th>Application ID</th>
                            <th>Name</th>{
                                Apps.map((app: any) => {
                                    return <tr>
                                        <td>{app.appID}</td>
                                        <td>{app.appName}</td>
                                    </tr>
                                })}
                        </table></div>
                }



            </div>
        </Fragment>

    )
}
