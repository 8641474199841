import React, { useEffect, useState , ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Accordian } from '../Accordian/accordian';
import CustomLoader from '../common/loader/component.loader';
import { fetchingTenant } from '../Tenant/TenantList/tenant.actions';
import { getTenantList } from '../Tenant/TenantList/tenant.selector';
import './viewTenant.css'


export default function ViewTenantList() {
    const dispatch = useDispatch();
    const allTenants = useSelector(getTenantList);
    let tenants: Array<object> = [];

    const [tenantList, setTenantList] = useState(tenants)
    const [search, setSearch] = useState('');
    useEffect(() => {
        if (allTenants.tenantList && !search) {

            setTenantList(allTenants.tenantList)
        }
    }, [allTenants, search])

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        var value = event.target.value
        setSearch(value)
        searchTenant(value);
        }
    const searchTenant = (searchValue: string) =>{

        let tenantsList = allTenants.tenantList.filter((value: any) => {
            return value.tenantName.toLowerCase().includes(searchValue.toLowerCase()) || value.tenantKey.includes(searchValue)
             || value.tenantID.toString().includes(searchValue)
        })
        if (tenantsList.length <= 0) {
            let elemnt = document.getElementById('no-search-results')
            if (elemnt) {
                elemnt.style.display = 'block'
            }
        }
        else {
            let elemnt = document.getElementById('no-search-results')
            if (elemnt) {
                elemnt.style.display = 'none'
            }

        }
        setTenantList(tenantsList);
    }

    useEffect(() => {
        dispatch(fetchingTenant())

    }, [dispatch])

    return (
        <div className='view-tenant-container'>
            {allTenants.loading ? <CustomLoader/> :
                <>
                 <input type='text' name='search' placeholder='Search' className='search-input'
                        onChange={handleSearch} />
                        <div id='no-search-results' style={{ display: 'none' }}>
                        No matching results!!!
                    </div>

                    <div className='accordian-container'>
                <div className='acoordian-wrapper head'>
                    <div className='tenant-info'>
                        <div className='title-class tenant-id head1'>ID </div>
                        <div className='title-class font-weight tenant-key head2'>Applications</div>
                        <div className='title-class  tenant-name-class head3'>Tenant  Name</div>
                    </div>
                    </div>
                    </div>
                    {tenantList.map((item: any) => {
                        return (
                            <Accordian {...item} />
                        )
                    })}
                </>
            }
        </div>
    )
}
