import { fetchedLocaleDataFailed, fetchLocaleData,
  fetchLocaleDataSuccess, addApplication,
  addApplicationSuccess, addApplicationFailed } from './addApplication.actions';
import { createReducer } from '@reduxjs/toolkit';


export interface localeData {

  loading: boolean;
  localeList: Array<object>;
  submitLoading: boolean,
  payloadList: Array<object>,
  alertMessage:string,
  submit:boolean

}

const initialState: localeData = {
  loading: false,
  localeList: [],
  payloadList: [],
  submitLoading: false,
  alertMessage:'',
  submit:false,
}

export const localeDataReducer = createReducer(initialState,builder => {
  builder.addCase(fetchLocaleData, (state, action) => {
    return {
      ...state,
      loading: true,
      submit:false
    };
  });

  builder.addCase(fetchLocaleDataSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      submit:false,
      localeList: action.payload.localeResult,
      payloadList: action.payload.appResult
    };
  });
  builder.addCase(fetchedLocaleDataFailed, (state, _) => {
    return {
      ...state,
      loading: false,
      submit:false
    };
  });
  builder.addCase(addApplication, (state, _) => {
    return {
      ...state,
      submitLoading: true,
      submit:false
    };
  });
  builder.addCase(addApplicationSuccess, (state, _) => {
    return {
      ...state,
      submitLoading: false,
      alertMessage:'Application Added SuccessFully',
      submit:true,
    };
  });
  builder.addCase(addApplicationFailed, (state, _) => {
    return {
      ...state,
      submitLoading: false,
      alertMessage:'Failed to add Application',
      submit:false,

    };
  });

});
