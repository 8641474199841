import { UserDetailsTypes } from '../../shared/types/type';
import { fetchUserDetails, fecthUserDetailsSuccess, fecthUserDetailsFailed } from './UserList.actions';
import { createReducer } from '@reduxjs/toolkit';
export interface UserDetails {

    loading: boolean;
    Users: UserDetailsTypes[];

}

const initialState: UserDetails = {
    loading: false,
    Users: []
}

export const userDetailsReducer = createReducer(initialState,builder => {
    builder.addCase(fetchUserDetails, (state, action) => {
        return {
            ...state,
            loading: true,
            Users:[]
        };
    });

    builder.addCase(fecthUserDetailsSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            Users: action.payload
        };
    });

    builder.addCase(fecthUserDetailsFailed, (state, _) => {
        return {
            ...state,
            loading: false,
            Users:[]
        };
    });
});
