import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'shards-react';
import CustomButton from '../button/button';
import './modalbutton.css';

interface Props {
    headerText: string,
    MainText: any,
    onSubmit: (data: any,) => void,
    theme: string,
    buttonText: any,
    data?: string,
    loading: boolean,
    classes: string,
    disabled?:boolean


}
const ModalButton = (props: Props) => {
    const [show, setShow] = useState(false)
    const toggleModal = () => {
        setShow(!show)
    }
    const { headerText, MainText, onSubmit, buttonText, data, loading, classes,disabled } = props
    return (<>
        <CustomButton
            loading={loading}
            onClick={toggleModal}
            classes={classes}
            buttontext={buttonText}
            disabled={disabled}
        />
        <Modal size='lg'
            open={show} toggle={toggleModal}>
            <ModalHeader>{headerText}</ModalHeader>
            <ModalBody><span>{MainText}</span></ModalBody>
            <ModalFooter><Button onClick={toggleModal}>No</Button>
                <Button
                    onClick={() => { onSubmit(data); toggleModal() }}>Yes</Button></ModalFooter>
        </Modal>
    </>
    )

}


export default ModalButton;