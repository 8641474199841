import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Application } from '../../../shared/types/type';
import DropDown from '../../common/dropdown/dropdown';
import ModalButton from '../../common/ModalButton/ModalButton';
import { saveApplication } from '../config.actions';
import { selectApplicationsDetails } from '../config.selector';
var _ = require('lodash');
interface MatchParams {
    tenantName?: string,
    appName?: string,
    tenantId?: string | undefined,
    appId?: string
}
interface Props extends RouteComponentProps<MatchParams> {

}

function UserIdentification(props: Props) {
    const dispatch = useDispatch();
    const applicationInfo = useSelector(selectApplicationsDetails);
    const [showAlertMessage, setShowAlertMessage] = useState(false);
    let initialstate: Application = _.cloneDeep(applicationInfo.applications[0]);
    const [customValue, setCustomValue] = useState('')
    const [applications, setApplication] = useState(initialstate);
    const [selectedIdentity, setSelectedIdentity] = useState('');
    let userInitialState = [
        { id: 1, name: 'Application Id', code: 'ApplicationID', value: '' },
        { id: 2, name: 'Apty Id', code: 'letznavId', value: '' },
        { id: 3, name: 'Custom', code: 'Custom', value: '' }]
    const [identies] = useState(userInitialState);

    const handleChange = (key: string, value: string) => {

        if (key === 'Custom') {
            setCustomValue(value)
        }
        else {
            setSelectedIdentity(value);
        }

    }
    const onSubmitAppDetails = () => {

        const application = _.cloneDeep(applications) || {};
        application.tenantId = props?.match?.params?.tenantId;
        application.userIdentification = {
            'type': selectedIdentity,
            'value': customValue

        }

        dispatch(saveApplication(application))
        setShowAlertMessage(true);
    }
    useEffect(() => {
        setApplication(initialstate)
        setCustomValue(initialstate?.userIdentification?.value as string)
        setSelectedIdentity(initialstate?.userIdentification?.type)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const closePopup = () => {
        setShowAlertMessage(false)
    }

    return (
        <div className='config-body'>
            <p className='config-heading'>User Identification</p>
            <div style={{ float: 'left' }}>
                <label>Identify users by:</label>
                <DropDown
                    payload={identies}
                    apptype={true}
                    onChange={handleChange}
                    className=' user-dropdown option-css'
                    selected={selectedIdentity}
                />
            </div>
            { selectedIdentity === 'Custom' && <div className='full-width'>
                <label >IDENTIFICATION KEY</label>
                <input className='attribute-priority-input'
                    value={customValue}
                    onChange={event => handleChange('Custom', event?.target.value)} />
            </div>}
            <ModalButton
                classes={'config-submit-button'}
                loading={applicationInfo.submitLoading}
                theme='white'
                headerText='Update Application'
                MainText={<span>You are trying to Update Application <strong>{applications?.name}</strong> </span>}
                buttonText='Submit'
                onSubmit={onSubmitAppDetails}
            />

            {showAlertMessage && applicationInfo.message && !applicationInfo.submitLoading && <div className='error-message'>
                {applicationInfo.message}
                <span className='closebtn' onClick={closePopup}>&times;</span>

            </div>}
        </div>
    )
}

export default UserIdentification;