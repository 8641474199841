import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import React, { useEffect, useState } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { useDispatch, useSelector } from 'react-redux';
import { InjectedHookType, scriptTypes } from '../../shared/types/type';
import CustomLoader from '../common/loader/component.loader';
import ModalButton from '../common/ModalButton/ModalButton';
import { selectInjectedData } from './injectedhook.selector';
import { fetchInjectedHook, saveInjectedHook } from './injectedHooks.actions';

interface Props {
    tenantID: number,
    tenantName: string
}
const InjectedHook = (props: Props) => {
    const dispatch = useDispatch();
    const InjectedScript = useSelector(selectInjectedData)
    const [injecteHookValue, setInjectedHookValue] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);

    useEffect(() => {
        setInjectedHookValue(InjectedScript.data)
    }, [InjectedScript])

    useEffect(() => {
        dispatch(fetchInjectedHook(props.tenantID));
    }, [dispatch, props.tenantID])

    useEffect(() => {
        if(InjectedScript.data === injecteHookValue){
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [injecteHookValue])

    const closePopup = () => {
        setShowMessage(false)
    }

    const onSubmit = () => {
        let script: scriptTypes = { script: injecteHookValue };

        let payload: InjectedHookType = {
            script: script,
            tenantId: props.tenantID
        }

        dispatch(saveInjectedHook(payload));
        setShowMessage(true);
    }
    if ( InjectedScript.loading) {
        return <CustomLoader />
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h2>Injected Hook</h2>
            <CodeMirror
                value={injecteHookValue}
                options={{
                    mode: 'xml',
                    theme: 'material',
                    lineNumbers: true
                }}
                onBeforeChange={(editor, data, value) => {
                    setInjectedHookValue(value)
                }}
                onChange={(editor, data, value) => {
                    setInjectedHookValue(value)
                }}
            />
            <div style={{ marginBottom: '20px' }}>

                <ModalButton
                    classes={'config-submit-button'}
                    loading={InjectedScript.submitLoading}
                    theme='white'
                    headerText='Update Application'
                    MainText={<span>You are trying to add injected hook script into the tenant Id:<strong>
                         {props.tenantID}</strong> and Tenant Name: <strong>{props.tenantName}</strong> </span>}
                    buttonText='Submit'
                    disabled={submitDisabled}
                    onSubmit={onSubmit}
                />
                {showMessage && InjectedScript.message && !InjectedScript.submitLoading && <div className='error-message'>
                    {InjectedScript.message}
                    <span className='closebtn' onClick={closePopup}>&times;</span>

                </div>}
            </div>
        </div>
    )
}
export default InjectedHook;