import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import CustomLoader from '../common/loader/component.loader';
import { changeRoute } from '../../shared/helpers/routing.helper';
import { PATHS } from '../../utils/path';
import { fetchLocaleData } from '../Tenant/AddAplication/addApplication.actions';
import { selectLocaleData } from '../Tenant/AddAplication/addApplication.selector';
import { fetchingApplication } from './config.actions';
import './Config.css';
import { selectApplicationsDetails } from './config.selector';
import ConfigNavbar from './ConfigNavbar';
import Custom from './CustomJs/Custom';
import EnvironmentSetup from './Environment/EnvironmentSetup';
import GeneralSetup from './GeneralSetup/GeneralSetup';
import UserIdentification from './UserIdentification/UserIdentification';


interface MatchParams {
    tenantName?: string,
    appName?: string,
    tenantId?: string,
    appId?: string
}

interface Props extends RouteComponentProps<MatchParams> {

}
const Config = (props: Props) => {
    const dispatch = useDispatch();
    const applicationInfo = useSelector(selectApplicationsDetails);
    const getLocaleData: any = useSelector(selectLocaleData);
    let payload = {

        tenantId: props.match.params.tenantId,
        appId: props.match.params.appId
    }

    const [selectedNavbar, setSelectedNavbar] = useState('Environment Setup');
    useEffect(() => {
        dispatch(fetchingApplication(payload))
        dispatch(fetchLocaleData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const Back = () => {
        changeRoute(PATHS.BASE);
    }


    if (applicationInfo.loadingApplication || getLocaleData.loading) { return <CustomLoader /> }
    return (
        <div className='config-container'>
            <Router>
                <ConfigNavbar
                    selectedNavbar={selectedNavbar}
                    Back={Back}
                    setSelectedNavbar={setSelectedNavbar}
                    tenantName={props.match.params.tenantName}
                    tenantId={props.match.params.tenantId}
                    appName={props.match.params.appName}
                    appId={props.match.params.appId} />
                <Switch>
                    <Route

                        path={PATHS.GENERAL_SETUP}
                        component={GeneralSetup}
                    />
                    <Route

                        path={PATHS.ENVIRONMENT_SETUP}
                        component={EnvironmentSetup}
                    />
                    <Route

                        path={PATHS.USER_IDENTIFICATION}
                        component={UserIdentification}
                    />
                    <Route

                        path={PATHS.CUSTOM}
                        component={Custom}
                    />
                </Switch>
            </Router>

        </div>
    )

}

export default withRouter(Config);