import { all } from 'redux-saga/effects';
import { getApplicationEffect } from '../components/configureComponents/config.effects';
import { getLocaleDataEffect } from '../components/Tenant/AddAplication/addApplication.effects';
import { tenantInfoEffects } from '../components/Tenant/TenantInfo/tenantInfo.effect';
import { tenantEffects } from '../components/Tenant/TenantList/tenant.effect';
import { loginEffects } from '../components/loginComponents/login.effect';
import { roleListEffects } from './../components/AddUser/addUser.effects';
import { InjectedHookEffects } from './../components/injectedHook/injectedHook.effects';
import { newTenantEffects } from './../components/NewTenant/newTenant.effects';
import { userDetailsEffects } from './../components/UserList/UserList.effects';
import { AnalyticsEffects } from '../components/analyticsComponents/analytics.effects';

export function* rootSaga() {
  yield all(
    [...loginEffects,
      ...tenantEffects,
      ...tenantInfoEffects,
      ...getLocaleDataEffect,
      ...getApplicationEffect,
      ...newTenantEffects,
      ...InjectedHookEffects,
      ...roleListEffects,
      ...userDetailsEffects,
      ...AnalyticsEffects]
  )

}