import { customPublishTypes } from '../../shared/types/type';
import { createAction } from '@reduxjs/toolkit';

import { Application } from '../../shared/types/type';

export interface paramDetails {
    tenantId: string | undefined,
    appId: string | undefined,
}
export const fetchingApplication = createAction<paramDetails>('FETCH_APPLICATION_INIT');
export const fetchedApplicationSuccess = createAction<Object>('FETCH_APPLICATION_SUCCESS');
export const fetchedApplicationFailed = createAction('FETCH_APPLICATION_FAILED');
export const saveApplication = createAction<Application>('SAVE_APPLICATION');
export const saveApplicationSucccess = createAction('SAVE_APPLICATION_SUCCESS');
export const saveApplicationFailed = createAction('SAVE_APPLICATION_FAILED');

export const customPublishInit = createAction<customPublishTypes>('PUBLISH_CUSTOMJS_INIT');
export const customPublishSuccess = createAction('PUBLISH_CUSTOMJS_SUCCESS');
export const customPublishFailed = createAction('PUBLISH_CUSTOMJS_FAILED');

