import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import apty from '../../assets/images/apty.png';
import { loginDetails } from '../../shared/types/type';
import AInput from '../common/Ainput/Ainput';
import CustomButton from '../common/button/button';
import { isEmailValid, isEmpty } from '../../utils/utils';
import { userLogin } from './login.action';
import './Login.css';
import { selectIsUserLogin } from './login.selector';

const LoginForm = (props: RouteComponentProps) => {
    const dispatch = useDispatch();
    const initialState: loginDetails = { email: '', password: '', source: 'Admin', instanceUrl: 'https://development.apty.io', role: '' };
    const [loginDetail, setLoginDetail] = useState(initialState);
    const [errorMessage, setErrorMessage] = useState('');
    const [invalidValue, setInvalidValue] = useState({
        password: false,
        email: false,

    })
    const userLoginDetails = useSelector(selectIsUserLogin);

    useEffect(() => {
        setErrorMessage(userLoginDetails.errorMessage)
    }, [userLoginDetails])
    const onSubmit = () => {

        let invalidstate = { ...invalidValue };
        invalidstate = {
            password: isEmpty(loginDetail.password),
            email: !isEmailValid(loginDetail.email)
        };

        setInvalidValue(invalidstate);

        if (Object.values(invalidstate).find(item => item)) {
            return;
        }
        dispatch(userLogin(loginDetail));
    }
    function onChange(key: keyof loginDetails, value: string) {
        
        setErrorMessage('');
        if (key === 'instanceUrl') {
            setLoginDetail({ ...loginDetail, instanceUrl: value })
        }
        if (key === 'role') {
            localStorage.setItem('instanceUrl', 'https://app.apty.io');
            localStorage.setItem('role',value);
            setLoginDetail({ ...loginDetail, instanceUrl: 'https://app.apty.io', role: value });
        }
        else {
            setLoginDetail({ ...loginDetail, [key]: value });
        }
    }

    const enterKeyHandler = (event: any) => {
        if (event.keyCode === 13 || event.which === 13) {
            onSubmit();
        }
    };

    return (
        <div className='login-form-container'>


            <img src={apty} alt='Logo' />

            <p className='login-heading'>Login</p>

            <div className='ui large buttons choice'>
                <button className={loginDetail.role === 'Marketing' ? 'ui button active' : 'ui button'}
                    value='Marketing'
                    onClick={e => onChange('role', 'Marketing')}
                >Marketing</button>
                <div className='or'></div>
                <button className={loginDetail.role === 'Dev' ? 'ui button active' : 'ui button'}
                 value='Dev'
                    onClick={e => onChange('role', 'Dev')}
                >Dev</button>
            </div>

            {loginDetail.role === 'Dev' && <div className='login-input-container'>
                <label>EnvironMent Url</label>
                <select name='instanceUrl' value={loginDetail.instanceUrl}
                    onChange={e => onChange('instanceUrl', e.target.value)}
                    required
                >
                    <option value='https://development.apty.io' > Development </option>
                    <option value='https://qa.apty.io'          > QA </option>
                    <option value='https://staging.apty.io'     > Staging </option>
                    <option value='https://labs.apty.io'        > Labs </option>
                    <option value='https://app.apty.io'         > Production </option>
                    <option value='https://au.apty.io'         > Australia </option>
                    <option value='https://eu1.apty.io'         > Europe </option>
                </select>
            </div>}
            <div className='login-input-container'>
                <label>Username</label>
                <AInput
                    type='email'
                    name='email'
                    placeholder='Username'
                    value={loginDetail.email}
                    error={invalidValue.email && !isEmailValid(loginDetail.email)}
                    onChange={e => onChange('email', e.target.value)}
                    errorMessage='UserName is Mandatory'
                    onKeyDown={enterKeyHandler}
                />
            </div>

            <div className='login-input-container'>
                <label>Password</label>

                <AInput
                    type='password'
                    name='password'
                    placeholder='Password'
                    autoComplete='on'
                    error={invalidValue.password && isEmpty(loginDetail.password)}
                    value={loginDetail.password}
                    onChange={e => onChange('password', e.target.value)}
                    errorMessage='Password is Mandatory'
                    onKeyDown={enterKeyHandler}

                />
            </div>
            {errorMessage && <p className='errorMessage'>SomeThing went wrong Please try again with correct credentials</p>}
            <CustomButton
                id='CustomButton'
                classes='submit'
                onClick={onSubmit}
                buttontext='SUBMIT'
                disabled={(loginDetail.email && loginDetail.password) ? false : true}
                loading={userLoginDetails.loading}
            />
        </div>
    )
}



export default withRouter(LoginForm);