import React, { ChangeEvent } from 'react';
import './Ainput.css'

interface Props {
    className?: string,
    name?: string,
    error?: boolean,
    errorMessage?: string,
    type?: string,
    placeholder?: string,
    autoComplete?: string,
    value?: string,
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
    onKeyDown?: (e: any) => void
}

const AInput = (props: Props) => {

    let className, error, errorMessage, parentProps:
    JSX.IntrinsicAttributes & React.ClassAttributes<HTMLInputElement> & React.InputHTMLAttributes<HTMLInputElement>;
    ({ className, error, errorMessage, ...parentProps } = props)

    return (<><input
        onKeyDown={e => props.onKeyDown && props.onKeyDown(e)}
        className={`input ${className ? className : ''} ${error ? 'error' : ''}`}
        {...parentProps}
    />
        {error && <div className='input-errormessage'>{errorMessage}</div>}
    </>
    )

}
export default AInput;