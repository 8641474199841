import { fetchRoleList, fetchRoleListSuccess, fetchRoleListFailed, addNewUser, addNewUserSuccess, addNewUserFailed } from './adduser.actions';
import { createReducer } from '@reduxjs/toolkit';

export interface RolesList {

  loading: boolean;
  roleList: Array<Object>;
  submitLoading: boolean,
  userAdded: boolean

}
const initialState: RolesList = {
  loading: false,
  roleList: [],
  submitLoading: false,
  userAdded: false,
}

export const roleListReducer = createReducer(initialState,builder => {
  builder.addCase(fetchRoleList, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  });

  builder.addCase(fetchRoleListSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      roleList: action.payload
    };
  });

  builder.addCase(fetchRoleListFailed, (state, _) => {
    return {
      ...state,
      loading: false
    };
  });

  builder.addCase(addNewUser, (state, _) => {
    return {
      ...state,
      loading: false,
      submitLoading: true,
      userAdded: false
    };
  });

  builder.addCase(addNewUserFailed, (state, _) => {
    return {
      ...state,
      loading: false,
      submitLoading: false,
      userAdded: false,
      message:'Unable to add user'
    };
  });

  builder.addCase(addNewUserSuccess, (state, _) => {
    return {
      ...state,
      loading: false,
      submitLoading: false,
      userAdded: true,
      message:'User Added SuccessFully'
    };
  });

});
