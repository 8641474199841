export const HeaderItems = [
    {
        title:'Tenants',
        url: '/view-tenants',
        className: 'nav-links',
    },
    {
        title: 'Manage Tenants',
        url: '/tenants',
        className: 'nav-links'
    },
    {
        title: 'New Tenant',
        url: '/provisioning',
        className: 'nav-links'
    },
    {
        title: 'Content Migration',
        url: '/migration',
        className: 'nav-links'
    },
    {
        title: 'Log out',
        url: '#',
        className: 'nav-links-mobile'
    },
]