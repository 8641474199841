import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import axiosInstance from '../../../apis/axiosInstance';
import { fetchedTenantInfoFailed, fetchedTenantInfoSuccess, fetchingTenantInfo } from './tenantInfo.actions';
 export interface requestOptions {
    method: string;
    headers: HeadersInit;
    redirect: RequestRedirect;
}
function* TenantInfo(action: PayloadAction<{}>) {

    const featureFlagsResponse = yield axiosInstance.get('/tenant-' + action.payload + '/feature-flag.json');
    const featureFlagsResult = yield featureFlagsResponse.data;
    if (featureFlagsResponse.status === 200) {
        yield put(fetchedTenantInfoSuccess(featureFlagsResult));
    }
    else {
        yield put(fetchedTenantInfoFailed());
    }

}

export const tenantInfoEffects = [
    takeEvery([fetchingTenantInfo.type], TenantInfo),

];
