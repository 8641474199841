import React from 'react';
import LoginForm from '../loginComponents/login.component';
import { IsLoggedIn } from '../../utils/utils';
import TenantList from '../Tenant/TenantList/component.tenantlist';

function IsLoggedInComponent () {
  const isValidUser = IsLoggedIn();
    if (isValidUser) {
      return <TenantList />
    }
    return <LoginForm />;
}

export default IsLoggedInComponent;
