import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'shards-ui/dist/css/shards.min.css'
import { createStore } from './state';
import { rootSaga } from './saga/index';
import './components/Tenant/TenantList/Application.css';

import App from './App';
import createSagaMiddleware from 'redux-saga';

require('dotenv').config();

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(sagaMiddleware);
sagaMiddleware.run(rootSaga);

function root() {
  return (
    <div id='app'>
      <Provider store={store}>
        <App />
      </Provider>
    </div>
  );
}

const rootElement = document.getElementById('root');
render(root(), rootElement);
