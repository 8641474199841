import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router';
import { changeRoute } from './shared/helpers/routing.helper';
import { IsLoggedIn } from './utils/utils';

interface Props extends RouteComponentProps {
}
const Authentication = (WrappedComponent: React.ComponentType<any>) => {
  const Auth = (props: Props) => {

    const isLogged = IsLoggedIn();
     if(!isLogged)
     {
       changeRoute('/login')
     }
    return (

      isLogged ?

        <WrappedComponent {...props} />

        :<Fragment/>);
  }
  return Auth;
}
export default Authentication;


