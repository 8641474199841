import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomLoader from '../../common/loader/component.loader';
import { hasValue } from '../../../utils/utils';
import { fetchingTenantInfo } from './tenantInfo.actions';
import { getTenantInfo } from './tenantInfo.selector';

interface Props {
    tenantName: string;
    tenantKey: string;

}

const TenantInfo = (props: Props) => {
    const dispatch = useDispatch();
    const tenantInfo = useSelector(getTenantInfo);
    const tenantInfoDetail: any = tenantInfo.tenantInfoDetail;

    useEffect(() => {

        dispatch(fetchingTenantInfo(props.tenantKey));

    }, [dispatch, props.tenantKey])

    if (tenantInfo.loading || !hasValue(tenantInfo)) {
        return <CustomLoader />
    }
    return (
        <>
            <span className='tenant-info-title'>{props.tenantName}  </span>
            <div id='tenant-info-table-container' >
                <div style={{ marginBottom: '10px' }}>
                </div>
                <table id='tenant-info-table'>
                    <tbody>
                        {Object.keys(tenantInfoDetail).map((feature: string, i) => {

                            return (
                                <tr key={tenantInfoDetail[feature].key}>
                                    <td>{feature}</td>
                                    <td>{
                                        tenantInfoDetail[feature].type === 'flag'
                                            ? tenantInfoDetail[feature].status ? 'true' : 'false'
                                            : tenantInfoDetail[feature].status
                                    }</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            </>
    );


}

export default TenantInfo;