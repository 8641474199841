import { Appredirect, featuresFlag } from '../../../shared/types/type';
import { createAction } from '@reduxjs/toolkit';

export const fetchingTenantInfo=createAction<string>('FETCH_TENANT_INFO_LIST_INIT');
export const fetchedTenantInfoSuccess=createAction<featuresFlag>('FETCH_TENANT_INFO_LIST_SUCCESS');
export const fetchedTenantInfoFailed=createAction('FETCH_TENANT_INFO_LIST_FAILED');
export const setAppData = createAction<Appredirect>('SET_APP_DATA');
export const setAppName = createAction<string>('SET_APP_NAME');
export const setTenantName = createAction<string>('SET_TENANT_NAME');
export const setTenantId = createAction<number>('SET_TENANT_ID');
export const setTenantKey = createAction<string>('SET_TENANT_KEY');
export const setAppID = createAction<number>('SET_APP_ID');