import { put, takeEvery } from 'redux-saga/effects';
import axiosInstance from '../../apis/axiosInstance';
import { fetchAppTypes, fetchLocales, putAppTypes, putLocales,
    submitTenants, tenantAdded, tenantAdditionFailed } from './newTenant.actions';
 interface postOptions {
    body: {}
}

// ------------Fetching apptypes--------------------------------------------------//
function* fetchApp() {
    try {
        const appResponse = yield axiosInstance.get('/accounts/applications/v2/types');
        const localeResult = appResponse.data.payload;
        yield put(putAppTypes(localeResult));
    }
    // tslint:disable-next-line: no-console
    catch (appError) { console.log('error fetching app types ', appError) }
}

function* fetchLocale() {
    try {
        const localeResponse = yield axiosInstance.get('/assist/api/locales/v3')
        const responseResult = localeResponse.data;
        yield put(putLocales(responseResult));
    }
    // tslint:disable-next-line: no-console
    catch (localeError) { console.log('error fetching locales', localeError) }
}

function* submitTenant(provBody:any) {
    var requestOptions: postOptions = {
        body: provBody.payload
    };
     try {
        const response = yield axiosInstance.post( '/accounts/provision/v1', requestOptions.body)

        if (response.status === 200) {

            yield put(tenantAdded());
        }
        else {
            yield put(tenantAdditionFailed());
        }

    }
    catch (submitTenantError) {
        yield put(tenantAdditionFailed());}

    // send some request on frontend to show request was successful

}
export const newTenantEffects = [
    takeEvery([fetchAppTypes.type], fetchApp),
    takeEvery([fetchLocales.type], fetchLocale),
    takeEvery([submitTenants.type], submitTenant),

];