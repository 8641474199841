

export const insightsDemoData = {
    'from': '2021-02-01',
    'to': '2021-02-05',
    'appDomain': 'http://14.99.46.185:8889/',
    'tenantId': 232,
    'appType': 'CAPPM',
    'appId': 504,
    'envMode': 'qa',
    'departments': ['nasales', 'emeasales', 'Finance', 'IT']
}