import React from 'react';
import closeImage from '../../../assets/images/close_icon.svg';
import './custompopup.css';

const CustomModal = (props: any) => {


  const closeModal = () => {
    document.body.classList.remove('noscroll');
    if (props.closeCallback) {
      props.closeCallback();
    }

  }
  const onCancel = () => {
    document.body.classList.remove('noscroll');
    if (props.onCancel) {
      props.onCancel();
    }
  }
  const onBack = () => {
    document.body.classList.remove('noscroll');
    if (props.onBack) {
      props.onBack();
    }
  }


  return props.visible ? (
    <div
      id={'custom-modal'}
      className={`custom-modal ${props.modalClassName}`}
      onClick={event => {

        if ((event.target as HTMLInputElement).id === 'custom-modal') {
          onCancel();
        }
      }}
    >
      <div className={'custom-modal-content'}>
        {!props.hideHeader && <div className={'custom-modal-header'}>
          {props.onBack && (
            <div className={'backIcon back'} onClick={onBack}></div>
          )}
          <div>{props.title}</div>
          {!props.hideCross && (
            <div>
              <img
                onClick={closeModal}
                src={closeImage}
                className={'modal-close '}
                alt={'close'}

              />
            </div>
          )}
        </div>
        }
        <div className={'custom-modal-body'} style={props.bodyStyle}>
          {props.children}
        </div>

      </div>
    </div>
  ) : (
      <div style={{ display: 'none' }}></div>
    );
};

export default CustomModal;