export const PATHS = {
    GENERAL_SETUP: '/config/:tenantName/:tenantId/:appName/:appId/general-setup',
    ENVIRONMENT_SETUP: '/config/:tenantName/:tenantId/:appName/:appId/environment-setup',
    USER_IDENTIFICATION: '/config/:tenantName/:tenantId/:appName/:appId/user-identification',
    CUSTOM: '/config/:tenantName/:tenantId/:appName/:appId/custom',
    BASE: '/',
    LOGIN: '/login',
    TENANTS:'/tenants',
    NEW_TENANT: '/provisioning',
    MANAGE_APPLICATION: '/config/:tenantName/:tenantId/:appName/:appId/environment-setup',
    HEADER: '/*',
    VIEW_TENTANTS:'/view-tenants',
    MIGRATION_DETAILS: '/migration',
    USERS:'/:tenantId/users',
    MANAGE_ANALYTICS: '/config/:tenantName/:tenantId/:appName/:appId/assist',
    ASSIST:'/config/:tenantName/:tenantId/:appName/:appId/assist',
    ACTIVITIES:'/config/:tenantName/:tenantId/:appName/:appId/activities',
    INSIGHTS:'/config/:tenantName/:tenantId/:appName/:appId/',
  };