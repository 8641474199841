import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import axiosInstance from '../../apis/axiosInstance';
import { activitiesDataAddedFailed, activitiesDataAddedSucceed,
    assistDataAddedSucceed, assistsDataAddedFailed, insertActivitiesData,
    insertAssistData, insertInsightsData, insightsDataAddedFailed,
    insightsDataAddedSucceed } from './analytics.actions';

function* saveInsightsData(action: PayloadAction<string>) {
    try {
        const response = yield axiosInstance.post('http://52.206.58.31:8083/load/demoInsightsNew', action.payload);
        if (response.status === 200) {
            yield put(insightsDataAddedSucceed())
        }
    }
    catch (error) {
        yield put(insightsDataAddedFailed())
    }
}

function* saveAssistData(action: PayloadAction<string>) {
    try {
        const response = yield axiosInstance.post('http://52.206.58.31:8083/loadDemoData/json', action.payload);
        if (response.status === 200) {
            yield put(assistDataAddedSucceed())
        }
    }
    catch (error) {
        yield put(assistsDataAddedFailed())
    }
}

// Fetch URL's from .env
function* saveActivitiesData(action: PayloadAction<string>) {
    try {
        const response = yield axiosInstance.post('http://52.206.58.31:8083/load/activityTracking', action.payload);
        if (response.status === 200) {
            yield put(activitiesDataAddedSucceed())
        }
    }
    catch (error) {
        yield put(activitiesDataAddedFailed())
    }
}

export const AnalyticsEffects = [
    takeEvery([insertInsightsData.type], saveInsightsData),
    takeEvery([insertAssistData.type], saveAssistData),
    takeEvery([insertActivitiesData.type], saveActivitiesData),
];