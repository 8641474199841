import React, { useEffect, useState } from 'react';
import CustomButton from '../../common/button/button';
import '../analytics.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsUserLogin } from '../../loginComponents/login.selector';
import { getTenantInfo } from '../../Tenant/TenantInfo/tenantInfo.selector';
import { insightsDemoData } from '../../../utils/insightsDemoData';
import { getFromDate, getToDate } from '../../../utils/utils';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { insertInsightsData } from '../analytics.actions';
import { selectAnalyticsData } from '../analytics.selector';

const Insights = (props: any) => {
  const dispatch = useDispatch();
   // Modifying demo data according to the need
  const demoData ={ ...insightsDemoData,
     to: getToDate(),
     from:  getFromDate(),
     envMode: useSelector(selectIsUserLogin).UserDetails.instanceUrl,
     tenantId: useSelector(getTenantInfo).tenantId,
     appId: useSelector(getTenantInfo).appId,
     appType: useSelector(getTenantInfo).appName};

  var updatedData = JSON.stringify(demoData, null, 5);
  const analyticsStateData = useSelector(selectAnalyticsData);

  const [insightsData, setInsightsData] = useState(updatedData);
  const [analyticsData, setAnalyticsData] = useState({ loading: false, message: ' ' });
  const [displayMsg, setDisplayMsg] = useState(false);


  useEffect(() => {
    setAnalyticsData(analyticsStateData);
  }, [analyticsStateData])

  const setData = () => {
    dispatch(insertInsightsData(insightsData));
    setDisplayMsg(true);
  }

  const closePopup = () => {
    setDisplayMsg(false);
  }

  return (
    <div style={{ width: '90%' }}>
      <CodeMirror
        value={insightsData}
        className = 'code-mirror'
        onBeforeChange={(editor: any, data: any, value: any) => {
          setInsightsData(value);
        }}
        onChange={(editor: any, data: any, value: any) => {
          setInsightsData(value);
        }}
      />
      <div style={{ width: '10%', float: 'right' }}>
        <CustomButton
          buttontext={'Set Data'}
          onClick={setData}
        />
      </div>
      {!analyticsStateData.loading && displayMsg && <div className='display-message'>
        {analyticsData.message}
        <span className='closebtn' onClick={closePopup}>&times;</span>
      </div>}
    </div>
  );
};

export default Insights;