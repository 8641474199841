import React from 'react';
import './loader.css'
interface Props{
    buttonSpinner?:boolean
}

const CustomLoader = (props:Props) => {
    return (
        <div className={props.buttonSpinner ? 'button-loader' : 'loader-listing'}>
            <div className={props.buttonSpinner ? 'button-load' : 'loader'}></div>
        </div>
    );
};
export default CustomLoader;
