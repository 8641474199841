import React from 'react'
import { useState } from 'react'
import Environment from './EnvironMent'



interface Props {
    deleteEnvironmentEntry: (id: number) => void
    handleEnviromentChange: (event: any, id: number) => void,
    classes?: string,
    publishingEnvironment?: number,
}
interface initialstateTypes {
    key: number,
    id: number,
    mandatoryEnv: boolean,
    lastEnv: boolean,
    count: number,


}
const EnvironmentList = (props: Props) => {



    let initialstate: initialstateTypes[] = [{
        key: 1,
        id: 1,
        mandatoryEnv: true,
        lastEnv: false,
        count: 1
    },
    {
        key: 2,
        id: 2,
        mandatoryEnv: true,
        lastEnv: false,
        count: 2
    }]
    const [environmentData, setEnvironmentData] = useState(initialstate)

    const addEnvironment = () => {

        let newRowData = {
            key: environmentData.length + 1,
            id: environmentData.length + 1,
            mandatoryEnv: false,
            lastEnv: false,
            count: environmentData.length + 1
        }

        setEnvironmentData([...environmentData, newRowData])

    }

    const deleteEnvironment = (event: any, id: any) => {
        let environment = [...environmentData];
        environment.pop();
        setEnvironmentData(environment);
        props.deleteEnvironmentEntry(id);
    }
    return (
        <div>
            {environmentData && environmentData.map((elem: any) => {
                return (
                    <div id='environments' key={elem.id}>
                        <Environment
                            id={elem.id}
                            classes={props.classes}
                            mandatoryEnv={elem.mandatoryEnv}
                            lastEnv={elem.lastEnv}
                            handleEnviromentChange={props.handleEnviromentChange}
                            deleteEnvironment={deleteEnvironment}
                        />
                    </div>
                )
            })}
            <br />
            <div className='tooltip-container'>
            <button
                className={props.publishingEnvironment && props.publishingEnvironment <= environmentData.length ? ' disabled add-env' : 'add-env'}
                type='button'
                onClick={addEnvironment}
            >
                <span>
                    Add Environment
                    </span>

            </button>
            {props.publishingEnvironment && props.publishingEnvironment <= environmentData.length &&

                <i className='fa fa-info-circle tooltipConatiner' aria-hidden='true'   >
                    <span className='tooltiptext'>You can only add  {props.publishingEnvironment} EnvironMents</span>
                    </i>
            }
            </div>
        </div>
    )

}

export default EnvironmentList;