import { fetchInjectedHook, fetchInjectedHookSuccess, fetchInjectedHookFailed,
    saveInjectedHook, saveInjectedHookSuccess, saveInjectedHookFailed } from './injectedHooks.actions';
import { createReducer } from '@reduxjs/toolkit';

export interface InjectedState {
    loading: boolean;
    data: string;
    submitLoading: boolean,
    message: string
}

const initialState: InjectedState = {
    loading: false,
    data: '',
    submitLoading: false,
    message: ''
}

export const injectedHookReducer = createReducer(initialState,builder => {
    builder.addCase(fetchInjectedHook, (state, action) => {
        return {
            ...state,
            loading: true,
        };
    });

    builder.addCase(fetchInjectedHookSuccess, (state, action: any) => {
        return {
            ...state,
            loading: false,
            data: action.payload,

        };
    });
    builder.addCase(fetchInjectedHookFailed, (state, _) => {
        return {
            ...state,
            loading: false,
            data: ''
        };
    });
    builder.addCase(saveInjectedHook, (state, _) => {
        return {
            ...state,
            submitLoading: true
        };
    });
    builder.addCase(saveInjectedHookSuccess, (state, _) => {
        return {
            ...state,
            submitLoading: false,
            message: 'Saved Data SuccessFully'
        }
    });
    builder.addCase(saveInjectedHookFailed, (state, _) => {
        return {
            ...state,
            submitLoading: false,
            message: 'Failed to save details'
        }
    });

});