
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Application, ApplicationCustomConfigTypes } from '../../../shared/types/type';
import DropDown from '../../common/dropdown/dropdown';
import ModalButton from '../../common/ModalButton/ModalButton';
import { LINE_BREAK_REGEX } from '../../../utils/utils';
import { selectLocaleData } from '../../Tenant/AddAplication/addApplication.selector';
import { saveApplication } from '../config.actions';
import { selectApplicationsDetails } from '../config.selector';
import UrlConfigList from '../UrlConfigList';
var _ = require('lodash');

interface UrlTypes {
    IframeUrl: string,
    restrictionUrl: string,
}
interface MatchParams {
    tenantName?: string,
    appName?: string,
    tenantId?: string | undefined,
    appId?: string
}
interface Props extends RouteComponentProps<MatchParams> {

}
const GeneralSetup = (props: Props) => {

    const dispatch = useDispatch();

    const getLocaleData: any = useSelector(selectLocaleData);

    const applicationInfo = useSelector(selectApplicationsDetails);
    let initialstate: Application = _.cloneDeep(applicationInfo.applications[0]);
    const [applications, setApplication] = useState(initialstate);
    const [iframeUrl, setIframeurl] = useState('');
    const [restrictUrl, setRestrictUrl] = useState('');
    let initialUrlList: string[] | undefined = [];
    const [iframeUrlList, setIframeUrlList] = useState(initialUrlList);
    const [restrictUrlList, setRestrictUrlList] = useState(initialUrlList);
    const [showAlertMessage, setShowAlertMessage] = useState(false);

    const closePopup = () => {
        setShowAlertMessage(false)
    }

    let initialCustomConfig: ApplicationCustomConfigTypes | undefined = initialstate?.config?.customConfig;

    const [customConfig, setCustomConfig] = useState(initialCustomConfig);


    useEffect(() => {
        let customConfigValue = {
            ...customConfig,
            ignoreSelectorIdFunction: getFunctionBody(customConfig && customConfig.ignoreSelectorIdFunction),
            ignoreSelectorClassFunction: getFunctionBody(customConfig && customConfig.ignoreSelectorClassFunction),
            ignoreSelectorAttributeFunction: getFunctionBody(customConfig && customConfig.ignoreSelectorAttributeFunction),
            userIdRefreshConfig: (customConfig && customConfig.userIdRefreshConfig) || {
                selectors: [],
                currentUrls: []
            }
        };
        let restrictionUrlList = applications?.restrictURLs;
        let iframeUrlListValue = applications?.prodIFrameUrls;
        setIframeUrlList(iframeUrlListValue as string[])
        setRestrictUrlList(restrictionUrlList as string[])
        setCustomConfig(customConfigValue as ApplicationCustomConfigTypes);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applications])


    const onRestrictUrlChange = (key: keyof UrlTypes, value: string) => {
        setRestrictUrl(value);
    }
    const onIFrameUrlsChange = (key: keyof UrlTypes, value: string) => {
        setIframeurl(value);
    }

    const addUrlIframeUrl = () => {
        if (iframeUrl !== '') {
            let urlList = [...iframeUrlList];
            const allUrlList = [...urlList, iframeUrl];
            setIframeUrlList(allUrlList)
        }
    };

    const addRestrictUrl = () => {
        if (restrictUrl !== '') {
            let urlList = [...restrictUrlList];
            const allUrlList = [...urlList, restrictUrl];
            setRestrictUrlList(allUrlList)
        }
    }
    const deleteIframeUrl = (index: number) => {
        let iframeUrls = [...iframeUrlList];
        iframeUrls.splice(index, 1);
        setIframeUrlList(iframeUrls)
    }
    const deleteRestricturl = (index: number) => {
        let restrictUrls = [...restrictUrlList];
        restrictUrls.splice(index, 1)
        setRestrictUrlList(restrictUrls);
    }

    const getFunctionBody = (formattedFunction: string | undefined): string => {
        if (formattedFunction) {
            let rawBody: string = '';
            if (formattedFunction.startsWith('function') && formattedFunction.includes('{')) {
                rawBody = formattedFunction.slice(formattedFunction.indexOf('{') + 1, formattedFunction.lastIndexOf('}'));
            }
            return rawBody || formattedFunction;

        }


        return '';
    }
    const onSubmitAppDetails = () => {

        const application = _.cloneDeep(applications);
        application.tenantId = props?.match?.params?.tenantId;
        application.restrictURLs = restrictUrlList;
        application.prodIFrameUrls = iframeUrlList;
        application.config.customConfig = {
            ...customConfig,
            ignoreSelectorIdFunction: getFormattedFunction('ignoreSelectorIdFunction',
                customConfig && customConfig.ignoreSelectorIdFunction),
            ignoreSelectorClassFunction: getFormattedFunction('ignoreSelectorClassFunction',
                customConfig && customConfig.ignoreSelectorClassFunction),
            ignoreSelectorAttributeFunction: getFormattedFunction('ignoreSelectorAttributeFunction',
                customConfig && customConfig.ignoreSelectorAttributeFunction)
        };
        dispatch(saveApplication(application))
        setShowAlertMessage(true)

    }

    useEffect(() => {
        setApplication(initialstate);

        setCustomConfig(initialCustomConfig);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationInfo])

    const isFormattingNotRequired = (body: string): boolean => {
        try {

            // eslint-disable-next-line no-new-func
            const incomingDefinition = new Function(body);
            incomingDefinition();
            return body.startsWith('function'); // hacky way to check whether parsedBody has Function
        } catch (e) {
            return false;
        }
    };

    const getCustomConfigFunctionParams: any = {
        ignoreSelectorIdFunction: ['id'],
        ignoreSelectorClassFunction: ['className'],
        ignoreSelectorAttributeFunction: ['name', 'value']
    };

    const getFormattedFunctionDef = (args: string[], body: string, formatType: string): Function | string => {
        // eslint-disable-next-line no-new-func
        const definition = new Function(...args, body);
        return formatType === 'string' ? definition.toString() : definition;
    };

    const getFormattedFunction = (key: string, funcBody: string | undefined): string => {
        if (funcBody) {
            if (isFormattingNotRequired(funcBody)) {
                return funcBody.replace(LINE_BREAK_REGEX, '');
            }
            const params = getCustomConfigFunctionParams[key];
            const formattedFunc = getFormattedFunctionDef(params, funcBody, 'string') as string;
            return formattedFunc.replace(LINE_BREAK_REGEX, '');
        }
        return '';
    }


    const onFunctionChange = <T extends {}>(key: keyof ApplicationCustomConfigTypes, value: T) => {
        if (key === 'attributePriority') {
            setCustomConfig({
                ...customConfig,
                [key]: value.toString().split(',')
            } as ApplicationCustomConfigTypes)
        }
        setCustomConfig({
            ...customConfig,
            [key]: value
        } as ApplicationCustomConfigTypes)
    }

    const onUpdateChange = (key: keyof Application, value: string) => {
        setApplication({
            ...applications,
            [key]: value
        })
    }


    return (

        <div className='config-body'>
            <p className='config-heading'>General Settings</p>

            <form>
                <div style={{ display: 'flex' }}>
                    <div className='full-width'>
                        <label >Application Name</label>
                        <input className='application-name-input'
                            value={applications?.name || ''}
                            onChange={e => onUpdateChange('name', e.target.value)} />
                    </div>
                    <div className='full-width'>
                        <label style={{ marginLeft: '28px' }}>Application Key</label>
                        <input className='application-key-input'
                            value={applications?.appKey || ''}
                            onChange={e => onUpdateChange('appKey', e.target.value)} />
                    </div>
                </div>



                <div>
                    <label>CROSS ORIGIN IFRAME URLS</label>
                    <UrlConfigList
                        inputUrl={iframeUrl}
                        onChange={onIFrameUrlsChange}
                        addUrl={addUrlIframeUrl}
                        urlList={iframeUrlList}
                        deleteUrl={deleteIframeUrl}
                    />
                </div>

                <div className='full-width'>
                    <label>PLAYER RESTRICTION PATTERNS</label>
                    <UrlConfigList
                        inputUrl={restrictUrl}
                        onChange={onRestrictUrlChange}
                        addUrl={addRestrictUrl}
                        urlList={restrictUrlList}
                        deleteUrl={deleteRestricturl} />
                </div>

                <div className='input-container-add '>
                    <label>APPLICATION TYPE</label>
                    <DropDown
                        name='appTypeCode'
                        className='all-selector option-css'
                        onChange={onUpdateChange}
                        selected={applications?.appTypeCode}
                        payload={getLocaleData.payloadList.payload}

                    />
                </div>
                <p className='config-heading'>Ignore Selector Config</p>

                <div className='full-width'>
                    <div className='textarea-label'><span className='blue'>function</span>
                    ignoreSelectorIdFunction( <span className='blue'>id</span> )</div>
                    <textarea className='config-textarea' placeholder='Custom Function Body'
                        name='ignoreSelectorIdFunction'
                        onChange={event => onFunctionChange('ignoreSelectorIdFunction', event?.target.value)}
                        value={customConfig?.ignoreSelectorIdFunction}></textarea>
                </div>

                <div className='full-width'>
                    <div className='textarea-label'><span className='blue'>function</span> ignoreSelectorClassFunction( <span className='blue'>className</span> )</div>
                    <textarea className='config-textarea' placeholder='Custom Function Body'
                        value={customConfig?.ignoreSelectorClassFunction}
                        onChange={event => onFunctionChange('ignoreSelectorClassFunction', event?.target.value)}></textarea>
                </div>

                <div className='full-width'>
                    <div className='textarea-label'><span className='blue'>function</span> ignoreSelectorAttributeFunction( <span className='blue'>name , value</span> )</div>
                    <textarea className='config-textarea' placeholder='Custom Function Body'
                        onChange={event => onFunctionChange('ignoreSelectorAttributeFunction', event?.target.value)}
                    // value={customConfig?.ignoreSelectorIdFunction}
                    ></textarea>
                </div>
                <div className='full-width'>
                    <label >Attribute Priority</label>
                    <input className='attribute-priority-input'
                        value={customConfig?.attributePriority || ''}
                        onChange={event => onFunctionChange('attributePriority', event?.target.value)} />
                </div>
                <div className='full-width'>
                    <label >ENABLE DETECT TOP WINDOW DOM CHANGES</label>
                    <input className='enable' type='checkbox'
                        checked={customConfig?.isDetectTopWindowDOMChanges || false}
                        onChange={event => onFunctionChange('isDetectTopWindowDOMChanges', event?.target.checked)} />
                    <span className='enable-text'>Enable</span>
                </div>

                <div>
                    <label>CONTAINER SCORE THRESHOLD (%)</label>
                    <input className='threshold' type='number' min='0' max='100'
                        onChange={event => onFunctionChange('containerScoreThreshold', event.target.value)}
                        value={customConfig?.containerScoreThreshold || ''}
                    />
                    <span className='threshold-text'>%</span>
                </div>

                <ModalButton
                    classes={'config-submit-button'}
                    loading={applicationInfo.submitLoading}
                    theme='white'
                    headerText='Update Application'
                    MainText={<span>You are trying to Update Application <strong>{applications?.name}</strong> </span>}
                    buttonText='Submit'
                    onSubmit={onSubmitAppDetails}
                />
                {showAlertMessage && applicationInfo.message && !applicationInfo.submitLoading && <div className='error-message'>
                    {applicationInfo.message}
                    <span className='closebtn' onClick={closePopup}>&times;</span>

                </div>}

            </form>
        </div>
    )


}


export default GeneralSetup