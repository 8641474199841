import React from 'react';
import CustomLoader from '../../common/loader/component.loader';

interface Props {
    publishLoader: boolean,
    publishStatus: string,
    name: string

}
const EnvTitle = (props: Props) => {

    return (
        <>

            <span className='environment'>
                <span className='env-name'>{props.name}</span>
                {props.publishLoader ? <CustomLoader buttonSpinner={true} /> : <span className='env-title'>{props.publishStatus === 'UP_TO_DATE' ? 'Published' : props.publishStatus=== 'ROLLBACK' ? <i>Publsihed</i> : 'Not Published'}</span>}
            </span>
        </>
    )
}

export default EnvTitle