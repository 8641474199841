import Auth from '../auth';
import Header from '../components/HeaderComponent/Header'
import TenantList from '../components/Tenant/TenantList/component.tenantlist';
import Config from '../components/configureComponents/Config';
import NewTenant from '../components/NewTenant/NewTenant';
import { PATHS } from '../utils/path';
import UserList from '../components/UserList/UserList';
import AnalyticsConfig from '../components/analyticsComponents/analyticsConfig';
import IsLoggedInComponent from '../components/isLoggedIn/isLoggedInComponent';
import ViewTenantList from '../components/ViewTenant/viewTenantList';
import AddMigrationDetails from '../components/AddMigrationDetails/AddMigrationDetails';

const routes = [
  {
    path: PATHS.LOGIN,
    component: IsLoggedInComponent
  },

  {
    path: PATHS.HEADER,
    component: Header
  },
  {
    path: PATHS.MANAGE_APPLICATION,
    component: Auth(Config),
  },
  {
    path: PATHS.MANAGE_ANALYTICS,
    component: Auth(AnalyticsConfig),
  },
  {
    path: PATHS.BASE,
    exact: true,
    component: Auth(ViewTenantList),
  },
  {
    path: PATHS.TENANTS,
    exact: true,
    component: Auth(TenantList),
  },
  {
    path: PATHS.NEW_TENANT,
    exact: true,
    component: Auth(NewTenant)
  },
  {
    path: PATHS.USERS,
    exact: true,
    component: Auth(UserList)
  },
  {
    path: PATHS.VIEW_TENTANTS,
    exact: true,
    component: Auth(ViewTenantList),
  },
  {
    path: PATHS.MIGRATION_DETAILS,
    exact: true,
    component: Auth(AddMigrationDetails)
  },

];
export default routes;
