import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import copyicon from '../../../assets/images/copy-icon.png';
import { Appredirect } from '../../../shared/types/type';
import CustomButton from '../../common/button/button';
import CustomPopup from '../../common/CustomPopUp/CustomPopup';
import InjectedHook from '../../injectedHook/injectedHook';
import AddApplication from '../AddAplication/component.addApplication';
import TenantInfo from '../TenantInfo/component.tenantinfo';
import { fetchingTenant } from './tenant.actions';
import './tenantcard.css';
interface Props {
    appList: Array<Object>;
    tenantName: string;
    tenantKey: string;
    tenantID: number;
    onClose: () => void,
    onOpen: () => void,
    redirect: (appData: Appredirect,
        redirectUrl : string) => void
}

type TypeName = 'InjectedHook' | 'AddApplication';

const TenantCard = (props: Props) => {

    const dispatch = useDispatch();
    const [showApplicationModal, setShowApplicationModal] = useState(false);
    const [showInjectHookModal, setInjectedHookModal] = useState(false);
    const [showTenantInfo, setShowTenantInfo] = useState(false);
    const apps = props.appList && props.appList.map((app: any) => {

        let appData: Appredirect = {
            tenantName: props.tenantName,
            tenantId: props.tenantID,
            appName: app.appName,
            appId: app.appID,
            tenantKey: props.tenantKey
        }

        return (
            <div key={app.appID} className='react-sanfona-element'>
                {app.appName}
                <i className='fa fa-gear' onClick={() => { props.redirect(appData,'environment-setup') }}></i>
                {/* <i className='fa fa-area-chart' onClick={() => { props.redirect(appData,'assist') }}></i> */}
            </div>
        )
    })


    const onClickHandler = (type: TypeName) => {
        if (type === 'InjectedHook') {
            setInjectedHookModal(true);
        }
        else if(type === 'AddApplication'){
            setShowApplicationModal(true);
        }
    }
    const onCancel = (submit: boolean) => {
        if (submit) {
            dispatch(fetchingTenant())
        }
        setShowApplicationModal(false)
        setInjectedHookModal(false);
        setShowTenantInfo(false);

    }



    const copyTenantKey = () => {
        const el = document.createElement('textarea');
        el.value = props.tenantKey;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    return (
        <div  >
            <CustomPopup
                footer={null}
                title={showTenantInfo ? 'Features Flag' : showInjectHookModal? 'Injected Hook' : 'Add Application'}
                visible={showApplicationModal || showInjectHookModal || showTenantInfo}
                closable={true}
                maskClosable={false}
                keyboard={false}
                hideCross={false}
                modalClassName='add-application-container'
                onCancel={() => onCancel(false)}
                closeCallback={() => onCancel(false)}
            >
                {showApplicationModal ? <AddApplication
                    tenantID={props.tenantID}
                    tenantName={props.tenantName}
                    tenantKey={props.tenantKey}
                    onCancel={onCancel}
                /> :
                    showInjectHookModal ? <InjectedHook
                        tenantID={props.tenantID}
                        tenantName={props.tenantName}
                    />
                        : showTenantInfo && <TenantInfo tenantName={props.tenantName} tenantKey={props.tenantKey} />
                }

            </CustomPopup>
            <div className='tenant-card'>
                <div className='tenant-name-container'>
                    <span onClick={() => { setShowTenantInfo(true) }}><i title='Tenant info' className='fa fa-info-circle'></i></span>
                    <span className='tenant-name'>{props.tenantName}</span>
                    <Link to={`/${props.tenantID}/users`}><i className='fa fa-user'></i></Link>

                </div>
                <div className='tenant-key-container'>
                    <span style={{ marginLeft: '20px' }}>
                        <span className='key-title'>Key: </span>
                        <span className='key-value'>{props.tenantKey}</span>
                        <img src={copyicon} className='copy-icon'
                        height='30px' alt='copy' onClick={copyTenantKey} title='copy tenant key' />
                    </span>
                    <span style={{ marginRight: '10px' }} >
                        <span style={{ color: '#dcdcdc' }}>Id: </span>
                        <span>{props.tenantID}</span>
                    </span>
                </div>

                <div className='tenant-card-app-container'>

                    {apps}
                </div>
                <div className='tenant-footer'>
                    <CustomButton buttontext='Add Application'
                        onClick={() => onClickHandler('AddApplication')} />
                    <CustomButton buttontext='Injected Hook'
                        classes='injected-hook-button'
                        onClick={() => onClickHandler('InjectedHook')} />

                </div>
            </div>
        </div>
    )
}

export default TenantCard;