import React from 'react';
import { Link } from 'react-router-dom';
interface Props {
  Back?: () => void;
  tenantName?: string;
  appName?: string;
  tenantId?: string;
  appId?: string;
  selectedNavbar: string;
  navData: {
    navbarListData: string;
    class?: string;
    toUrl?: string;
    id?: string;
  }[];
  setSelectedNavbar: (selectedvalue: string) => void;
}

function AnalyticsNavbar(props: Props) {
  return (
    <div className='config-navbar'>
      <div onClick={() => props.Back && props.Back()} className='back-button'>
        <i className='fa fa-arrow-left pulse'></i>
      </div>
      <ul className='menu-items'>
        {props.navData.map(val => {
          return (
            <Link
              to={`/config/${props.tenantName}/${props.tenantId}/${props.appName}/${props.appId}/${val.toUrl}`}
              className='textDecoration-none'
            >
              <li
                id={val.id}
                className={
                  props.selectedNavbar === val.navbarListData
                    ? 'menu-item active'
                    : 'menu-item'
                }
                onClick={() => {
                  props.setSelectedNavbar(val.navbarListData);
                }}
              >
                {val.navbarListData}
              </li>
            </Link>
          );
        })}
      </ul>
    </div>
  );
}

export default AnalyticsNavbar;
