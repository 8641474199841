import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import { Application } from '../../shared/types/type';
import axiosInstance from '../../apis/axiosInstance';
import { customPublishTypes } from '../../shared/types/type';
import { transformApplicationToSave } from './../../utils/utils';
import { customPublishFailed, customPublishInit, customPublishSuccess,
    fetchedApplicationFailed, fetchedApplicationSuccess, fetchingApplication,
    paramDetails, saveApplication, saveApplicationFailed, saveApplicationSucccess } from './config.actions';

export interface resultobject {
    localeResult: Array<object>,
    appResult: Array<object>,

}
function* getApplicationInfo(action: PayloadAction<paramDetails>) {

    const accountResponse = yield axiosInstance.get(`/accounts/applications/v3/tenant/${action.payload.tenantId}/application/${action.payload.appId}/config`);
    const appAccountDetails = yield accountResponse.data;
    const assitResponse = yield axiosInstance.get(`assist/api/applications/v2/tenant/${action.payload.tenantId}/application/${action.payload.appId}/config`);
    const appAssisDetails = yield assitResponse.data;
    const applicationResult = (appAccountDetails as any).Payload as Application[];
    const applications = applicationResult.map(app => {
        return { ...app, ...(appAssisDetails) };
    });
    if (accountResponse.status === 200) {

        yield put(fetchedApplicationSuccess(applications));
    }
    else {
        yield put(fetchedApplicationFailed());
    }
}

function* saveApplicationDetails(action: PayloadAction<Application>) {
    const dataToSave = transformApplicationToSave(action.payload);
    const saveDetails = yield axiosInstance.put('/accounts/applications/v3/update', dataToSave)
    const saveToAssist = yield axiosInstance.put(`assist/api/applications/v2/${action.payload.id}/updateApplication`, dataToSave)
    if (saveDetails.status === 200 && saveToAssist.status === 200) {
        yield put(saveApplicationSucccess())
    }
    else {
        yield put(saveApplicationFailed())
    }
}
function* customJsPublish (action: PayloadAction<customPublishTypes>) {

    try {
        const response = yield axiosInstance.put(`assist/api/applications/v2/tenant/${action.payload.tenantId}/${action.payload.appId}/publish-custom-js-sa`,action.payload)
        if (response.status === 200) {
            yield put(customPublishSuccess())
        }
        else {
            yield put(customPublishFailed());
        }

    }
    catch (error) {
        yield put(customPublishFailed())

    }
}

export const getApplicationEffect = [
    takeEvery([fetchingApplication.type], getApplicationInfo),
    takeEvery([saveApplication.type], saveApplicationDetails),
    takeEvery([customPublishInit.type],customJsPublish)

];
