import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import axiosInstance from '../../apis/axiosInstance';
import { changeRoute } from '../../shared/helpers/routing.helper';
import { authRequestOptions, loginDetails } from '../../shared/types/type';
import { userLoggedIn, userLoggedInFailed, userLogin } from './login.action';

function* UserLogin(action: PayloadAction<loginDetails>) {

    var authHeaders = new Headers()
    authHeaders.append('Content-Type', 'application/json')

    var authData = JSON.stringify(action.payload)

    var authRequestOps: authRequestOptions = {
        method: 'POST',
        headers: authHeaders,
        body: authData,
        redirect: 'follow'
    };

    try {
        const response = yield axiosInstance.post(`${action.payload.instanceUrl}/accounts/auth/v1/login`, authRequestOps.body);
        var authResult = yield response.data;

        if (response.status === 200) {

            localStorage.setItem('auth-token', authResult.token)
            localStorage.setItem('instanceUrl', action.payload.instanceUrl);
            yield put(userLoggedIn(authResult));
            changeRoute('/')
        }
        else {
            yield put(userLoggedInFailed(authResult.error));
        }

    }
    catch (error) {
        yield put(userLoggedInFailed({ message: 'Some Thing went wrong Please try again with correct credentials' }));

    }

}

export const loginEffects = [
    takeEvery([userLogin.type], UserLogin),

];
