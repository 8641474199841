import React from 'react';
import './Logout.css';
interface Props {
    onClick: () => void;
}
const Logout = (props: Props) => {

    return (
        <button className={`btn btn--primary btn--medium`}
            onClick={props.onClick}>Logout
        </button>
    )
}

export default Logout;