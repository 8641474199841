import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import React, { useEffect, useState } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Application, customPublishTypes, EnvironmentsStatus } from '../../../shared/types/type';
import CustomPopup from '../../common/CustomPopUp/CustomPopup';
import ModalButton from '../../common/ModalButton/ModalButton';
import { customPublishInit, saveApplication } from '../config.actions';
import { selectApplicationsDetails } from '../config.selector';
import Envtitle from './EnvTitle';
import PublishStatusItem from './publishStatus';
var _ = require('lodash');
interface MatchParams {
    tenantName?: string,
    tenantId?: string | undefined,
    appId?: string
}

interface Props extends RouteComponentProps<MatchParams> {

}
interface SelectedstatusTypes {

    status: string,
    env: number
}

function Custom(props: Props) {

    const [showPublishModal, setShowPublishModal] = useState(false);
    const dispatch = useDispatch();
    const [customScript, setCustomScript] = useState('');

    const applicationInfo = useSelector(selectApplicationsDetails);
    let initialstate: Application = _.cloneDeep(applicationInfo.applications[0]);
    let initialStatus: EnvironmentsStatus = initialstate.customJSPublishingStatus;
    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [showPublishAlertMessage, setPublishAlertMessage] = useState(false)
    const [publishStatus, setPublishStatus] = useState(initialStatus);
    const [applications, setApplication] = useState(initialstate);
    const selectedStatusInitialState: SelectedstatusTypes = {
        status: '',
        env: 1
    }
    const [selectedStatus, setSelectedStatus] = useState(selectedStatusInitialState)

    const onSubmitAppDetails = () => {
        const application = _.cloneDeep(applications) || {};
        application.script = customScript;
        dispatch(saveApplication(application))
        setShowAlertMessage(true);
    }
    const closePopup = () => {
        setShowAlertMessage(false)
        setPublishAlertMessage(false)
    }

    useEffect(() => {
        setPublishStatus(initialstate?.customJSPublishingStatus)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        setApplication(initialstate)
        setCustomScript(initialstate?.script || '')

        if (applicationInfo?.published) {

            setPublishStatus({
                ...publishStatus,
                [selectedStatus.env]: selectedStatus.status
            })
        }
        else {
            setPublishAlertMessage(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationInfo])

    const publishedCustomJs = (env: number, status: string) => {

        let payload: customPublishTypes = {
            status: status,
            environment: env,
            appId: parseInt(props.match.params.appId || ''),
            tenantId: parseInt(props.match.params.tenantId || '')
        }

        setSelectedStatus({
            status: status,
            env: env
        })

        dispatch(customPublishInit(payload))
        setShowPublishModal(false);
        setPublishAlertMessage(true)

    }


    const checkDisability = (status: string): boolean => {
        if(status==='UP_TO_DATE') {
            return false;
        }
        return true
    }
    return (
        <div className='config-body custom-js'>
            <CustomPopup
                footer={null}
                visible={showPublishModal}
                closable={setShowPublishModal}
                maskClosable={true}
                keyboard={false}
                hideHeader={true}
                modalClassName='add-application-container'
                onCancel={setShowPublishModal}
                closeCallback={setShowPublishModal}

            >
                <div className='custom-publish-popup'>
                    {Object.values(publishStatus).map((value, index) => {
                        let environmentName = applications?.environments[index + 1]?.name;
                        return (
                            <PublishStatusItem
                                disabled={Object.values(publishStatus).length===index+1? false: checkDisability(publishStatus[index+2])}
                                publishStatus={value}
                                publishedCustomJs={publishedCustomJs}
                                env={index + 1}
                                environmentName={environmentName}
                            />
                        )
                    })}


                </div>
            </CustomPopup>

            <div className='application-script-container'>
                <p className='title-label'>Application Level Javascript</p>
                <i className='fa fa-info-circle tooltipConatiner' aria-hidden='true'   >
                    <span className='tooltiptext'>Customize the application behavior at a Dom level</span></i>
            </div>

            {showPublishAlertMessage && applicationInfo.publishMessage && !applicationInfo.publishLoader && <div className='error-message'>
                {applicationInfo.publishMessage}
                <span className='closebtn' onClick={closePopup}>&times;</span>

            </div>}
            <div className='publish-container'>
                {
                    Object.values(publishStatus).map((value, index) => {
                        let environmentName = applications?.environments[index + 1]?.name;
                        return (
                            <Envtitle
                                publishStatus={value}
                                name={environmentName}
                                publishLoader={applicationInfo.publishLoader} />
                        )
                    })
                }

                <span className='test' onClick={() => setShowPublishModal(true)}></span>
            </div>

            <CodeMirror
                value={customScript}
                options={{
                    mode: 'xml',
                    theme: 'material',
                    lineNumbers: true
                }}
                onBeforeChange={(editor, data, value) => {
                    setCustomScript(value);
                }}
                onChange={(editor, data, value) => {
                    setCustomScript(value);
                }}

            />

            <ModalButton
                classes={'config-submit-button'}
                loading={applicationInfo.submitLoading}
                theme='white'
                headerText='Update Application'
                MainText={<span>You are trying to Update Application <strong>{applications?.name}</strong> </span>}
                buttonText='Submit'
                disabled={customScript ? false : true}
                onSubmit={onSubmitAppDetails}
            />

            {showAlertMessage && applicationInfo.message && !applicationInfo.submitLoading && <div className='error-message'>
                {applicationInfo.message}
                <span className='closebtn' onClick={closePopup}>&times;</span>

            </div>}
        </div>
    )
}
export default Custom;

