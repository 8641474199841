
export const assistDemoData = {

    'email': 'madhuriqa@yopmail.com',
    'password':'Apty@123',
    'source': 'admin',
   'from':'2021-02-15',
   'to': '2021-05-15',
   'locale':'en',
   'segmentNames': ['nasales', 'emeasales', 'finance'],
   'applicationName':'CAPPM',
   'applicationUrl': 'http://14.99.46.185:8889/',
   'envMode': 'qa',
   'flowsActionFrequency':50,
   'flowsPermalinkActionPercentage': 0.6,
   'flowsPriority':{
   		'test': 0
   },
   'flowStepDuration':0.5,
   'flowEndTypesPriority':{'end':0.7,'cancel':0.1,'anonymous':0.1,'stepStopped':0.2},
   'validationActionFrequency':40,
   'validationsPriority':{},
   'validationActionTypesPriority':{'pass':0.5,'fail':0.3,'notify':0.7},
   'tooltipsActionFrequency': 10,
   'tooltipsPriority':{},
   'tooltipActionTypesPriority':{'display':0.7,'action':0.3},
   'launchersActionFrequency':30,
   'launchersPriority':{},
   'launcherActionTypesPriority':{'display':0.5,'hovered': 0.4,'action':0.7},
   'announcementsActionFrequency': 50,
   'announcementsPermalinkActionPercentage': 0.4,
   'announcementsPriority':{},
   'announcementActionTypesPriority':{'display':0.5,'accept':0.4,'cancel':0.1},
   'knowledgeContentActionFrequency': 10,
   'knowledgeContentPermalinkActionPercentage': 0.1,
   'knowledgeContentPriority': {},
   'playerBannerActionFrequency':10,
   'playerSearchActionFrequency':30,
   'playerSearchExcludeKeywords': ['a', 'A', 'new', 'an', 'The', 'This', 'That', 'For', 'An', 'What', 'Where', 'When', 'Why'],
   'noOfUsers': 4000,
   'daysPriority': {},
   'workingTime':{'startTime':0,'endTime':23},
   'quadratic': { 'noOfDays': 120, 'x': -20, 'a': -0.07, 'b': -0.17, 'c': 100, 'reduction': 0.7},
   'randomize': 0.5,
   'isRunETL': true
}