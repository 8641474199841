import React from 'react'
import { Link } from 'react-router-dom'
interface Props {
    Back?: () => void,
    tenantName?: string,
    appName?: string,
    tenantId?: string,
    appId?: string,
    selectedNavbar?: string,
    setSelectedNavbar: (selectedvalue: string) => void
}

function ConfigNavbar(props: Props) {

    return (
        <div className='config-navbar'>

            <div onClick={() => props.Back && props.Back()} className='back-button'>
                <i className='fa fa-arrow-left pulse'></i>
            </div>
            <ul className='menu-items'>
                <Link to={`/config/${props.tenantName}/${props.tenantId}/${props.appName}/${props.appId}/environment-setup`} className='textDecoration-none'>
                    <li id='menu-item1'
                        className={props.selectedNavbar === 'Environment Setup' ? 'menu-item active' : 'menu-item'}
                        onClick={() => { props.setSelectedNavbar('Environment Setup') }}
                    >Environment Setup</li>
                </Link>
                <Link to={`/config/${props.tenantName}/${props.tenantId}/${props.appName}/${props.appId}/general-setup`} className='textDecoration-none'>
                    <li
                        id='menu-item2'
                        className={props.selectedNavbar === 'General Setup' ? 'menu-item active' : 'menu-item'}
                        onClick={() => { props.setSelectedNavbar('General Setup') }}
                    >General Setup</li>
                </Link>
                <Link to={`/config/${props.tenantName}/${props.tenantId}/${props.appName}/${props.appId}/user-identification`} className='textDecoration-none'>
                    <li
                        id='menu-item3'
                        className={props.selectedNavbar === 'User Identification' ? 'menu-item active' : 'menu-item'}
                        onClick={() => { props.setSelectedNavbar('User Identification') }}
                    >User Identification</li>
                </Link>
                {/* <Link to={`/config/${props.tenantName}/${props.tenantId}/${props.appName}/${props.appId}/custom`}
                className='textDecoration-none'>
                    <li id='menu-item5'
                        className={props.selectedNavbar === 'Custom JS' ? 'menu-item active' : 'menu-item'}
                        onClick={() => { props.setSelectedNavbar('Custom JS') }}
                    >Custom JS</li>
                </Link> */}
            </ul>
        </div>
    )
}

export default ConfigNavbar;
