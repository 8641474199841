import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/apty-icon-1.png'
import { changeRoute } from '../../shared/helpers/routing.helper'
import { PATHS } from '../../utils/path'
import Logout from '../Logout/Logout'
import './Header.css'
import { HeaderItems } from './HeaderItems'
import { RouteComponentProps } from 'react-router-dom';
import { getKeyByValue } from '../../utils/utils'
export interface LoginStatus {
    isLoggedIn: boolean
}

function Header(props: RouteComponentProps) {

    const [selectedTab, setSelectedTab] = useState('/view-tenants');
    const userType = localStorage.getItem('role');
    const onSelect = (item: string) => {
        setSelectedTab(item);
    }

    const logout = () => {
        localStorage.removeItem('auth-token');
        localStorage.removeItem('instanceUrl');
        changeRoute(PATHS.LOGIN)
    }

    const goToHome = () => {
        props.history.push(PATHS.BASE);
    }

    return (
        <div className={localStorage.getItem('auth-token') ? 'navbar-items' : 'display-none'}>

            <img src={logo} className='navbar-logo' height='45px' alt='Logo' onClick={goToHome} />
            <span className='environment-title'>
                <span>{getKeyByValue(localStorage.getItem('instanceUrl') || '')}</span>
                <span className='env-item'>Environment</span>
            </span>

            {userType === 'Dev' && <ul className={'nav-menu'}>
                {HeaderItems.map((item, index) => {
                    return (
                        <Link
                            onClick={e => onSelect(item.url)}
                            className={selectedTab === item.url ? `Active ${item.className}` : item.className}
                            key={index}
                            to={item.url}

                        >
                            <li>{item.title}</li>
                        </Link>
                    )
                })}
            </ul>}
            {userType === 'Marketing' && <ul className={'nav-menu'}>
                        <Link
                            onClick={e => onSelect('/view-tenants')}
                            className={selectedTab === '/view-tenants' ? `Active nav-links` : `'nav-links`}
                            to='/view-tenants'>
                            <li>Tenants</li>
                        </Link>
            </ul>}

            <Logout onClick={logout} />

        </div>
    );

}

export default Header