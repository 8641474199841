import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import { UserPayloadTypes } from '../../shared/types/type';
import axiosInstance from '../../apis/axiosInstance';
import { addNewUser, addNewUserFailed, addNewUserSuccess, fetchRoleList, fetchRoleListFailed, fetchRoleListSuccess } from './adduser.actions';

function* RoleList(action: PayloadAction<{}>) {
    try {
        const appResponse = yield axiosInstance.get(`/accounts/role/v1/tenant/${action.payload}`);

        const appResult = yield appResponse.data;
        if (appResponse.status === 200) {
            yield put(fetchRoleListSuccess(appResult))
        }
    }
    catch (error) {
        yield put(fetchRoleListFailed())
    }
}


function* addUser(action: PayloadAction<UserPayloadTypes>) {

    try {
        const response = yield axiosInstance.post(`/accounts/user/v1`, action.payload)

        if (response.status === 200) {
            yield put(addNewUserSuccess())
        }
        else {
            yield put(addNewUserFailed());
        }
    }
    catch (error) {
        yield put(addNewUserFailed());

    }

}
export const roleListEffects = [
    takeEvery([fetchRoleList.type], RoleList),
    takeEvery([addNewUser.type], addUser)
];