import React from 'react'
 interface Props {
    index: number,
    name: string,
    url: string,
    enablePlayer: boolean,
    environmentOnchange: any,
    defaultEnvForEditor: number
}
function EnvironmentSetupItem(props: Props) {
    return (
        <>
            <p className='header-env'>Environment #{props.index}</p>

            <div className='full-width flex'>
                <div className='name-env'>
                    <label>Name</label>
                    <input
                        type='text'
                        name='name'
                        value={props.name}
                        onChange={event => props.environmentOnchange('name', event?.target.value, props.index)}
                        placeholder='Name'
                    />
                </div>
                <div >
                    <label>Default</label>
                    <input name='defaultEnvForEditor' type='radio'
                        checked={props.index === props.defaultEnvForEditor}
                        onChange={event => props.environmentOnchange('defaultEnvForEditor', props.index, props.index)}
                    />
                    <div className='default-env-text'>Default env for studio</div>
                </div>
            </div>

            <div className='full-width'>
                <label>Environment URL</label>
                <input
                    type='text'
                    name='url'
                    value={props.url}
                    onChange={event => props.environmentOnchange('url', event?.target.value, props.index)}
                    placeholder='https://environment.com'
                />
            </div>

            <div className='full-width'>
                <input
                    className='enable'
                    type='checkbox'
                    name='enablePlayer'
                    checked={props.enablePlayer}
                    onChange={event => props.environmentOnchange('enablePlayer', event?.target.checked, props.index)}
                />
                <span className='enable-text'>Enable apty widget</span>
            </div>

        </>
    )
}

export default EnvironmentSetupItem;